// SecurityModal.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalBox from './ModalBox'  
import assets from '../../assets.json';

const SecurityModal = ({
  isOpen,
  handleClose,
  setChangePasswordModal,
  setChangeTransactionPasswordModal,
}) => {
  const { t } = useTranslation();
 
  return (
    <ModalBox
      isOpen={isOpen}
      handleClose={handleClose}
      title={t("Security")}
    >
      <div className="modal-notif-content">
        <button
          className="btnPassword"
          onClick={() => setChangePasswordModal(true)}
          style={{ backgroundImage: `url(${assets.PASSWORD_BTN_BG})` }}
        >
          <span>{t("Change Password")}</span>
        </button>
        <button
          className="btnPassword"
          onClick={() => setChangeTransactionPasswordModal(true)}
          style={{ backgroundImage: `url(${assets.PASSWORD_BTN_BG})` }}
        >
          <span>{t("Change Transaction Password")}</span>
        </button>
      </div>
    </ModalBox>
  );
};

export default SecurityModal;