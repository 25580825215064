
import React, {useEffect, useState, useContext } from 'react';

import { useNavigate } from 'react-router-dom'    
import { useTranslation } from 'react-i18next'; 
import Footer from './Footer';  
import DashboardHeader from './DashboardHeader'; 
import DashboardMines from './DashboardMines'; 
import SplashScreen from './SplashScreen';
import useSplashScreen from './useSplashScreen';
  
import { AudioProvider, AudioContext } from '../AudioContext';
 
export default function Dashboard() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();  
    const [loading, setLoading] = useState(true);
    const showSplashScreen = useSplashScreen(5);

    const { audio } = useContext(AudioContext);
    const [audioCheckboxState, setAudioCheckboxState] = useState(localStorage.getItem('audio'));

    useEffect(() => {
        const handleUserInteraction = () => { 
            if (audio !== null) {
                if (audioCheckboxState === "1") {
                    audio.play();
                } else {
                    audio.currentTime = 0;
                    audio.pause();
                }
            } 
        };
    
        // document.addEventListener('mousemove', handleUserInteraction);
        document.addEventListener('click', handleUserInteraction);
    
        return () => {
        //   document.removeEventListener('mousemove', handleUserInteraction);
          document.removeEventListener('click', handleUserInteraction);
        };
    }, [audio, audioCheckboxState]);
 
    return (
    <> 
        {showSplashScreen && (
            <SplashScreen />
        )}
        
        <div className="dashboard">   
            <DashboardHeader/>
            <DashboardMines  />
            <Footer/>
        </div> 
    </>
    );
};