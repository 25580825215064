import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';  
import BtnMining from './BtnMining';
import GoldMining from './GoldMining';
import GoldRock from './GoldRock'; 
import assets from '../../assets.json';
 
const AssetWater = ({ detail, type, onClickOne, onClickTwo }) => {

    const { t }     = useTranslation(); 
   
    const [audioCheckboxState, setAudioCheckboxState] = useState(localStorage.getItem('audio'));
    let mineEffect = new Audio("/audio/mining.mp3");
    mineEffect.volume = 1; 

    useEffect(() => { 
        if (audioCheckboxState === "1") {   
        } else {
            // Stop background music if playing
            if (mineEffect) {
                mineEffect.pause();
                mineEffect.currentTime = 0;
            }
        }

        // Cleanup on unmount
        return () => {
            if (mineEffect) {
                mineEffect.pause();
                mineEffect.currentTime = 0;
            }
        };
    }, [audioCheckboxState]);
   
    const silverImages = [assets.SILVER1, assets.SILVER2, assets.SILVER3, assets.SILVER4, assets.SILVER5, assets.SILVER6, assets.SILVER7];
    const [showGoldMining, setShowGoldMining] = useState(false);

    const startMining = () => {
     
        if (audioCheckboxState === "1") { 
            let loopCount = 0;
            const handleAudioEnded = () => {
              loopCount++;
              if (loopCount < 2) {
                mineEffect.play();
              } else {
                mineEffect.removeEventListener('ended', handleAudioEnded);
              }
            };
            mineEffect.addEventListener('ended', handleAudioEnded);
            mineEffect.play();
        }
 
        setShowGoldMining(true);
        setTimeout(() => { 
            onClickTwo();
        }, 3000);
        setTimeout(() => {
          setShowGoldMining(false);
        }, 5000);
    };
     
    return ( 
        <span 
            className="SlotMine SlotEmpty"
        >
            <img className="bg" src={assets.EMPTY_SLOT} width={152}/>
            <div className="SlotMineDetail"> 
                    <div> 
                        <div className="mining_slot" onClick={(event) => onClickOne(event)} >
                            <div className="bar_slot_info">
                                <img className="bar_slot_info_bg" src={assets.BAR_SLOT_INFO} width={80}/>
                                <span className="level_badge">
                                    <img src={assets.LEVEL_BASE} width={20} />
                                    <span>Lv<br/>{detail.level}</span>
                                </span>
                                <span>{t(detail.title)}</span>
                            </div>
                            <div className="mineType"> 
                                {detail.level >= 0 && detail.level < silverImages.length && (
                                    <img className="mineTypeBg" src={silverImages[detail.level]} width={100}/>
                                )}
                                <GoldRock level={detail.level }/>  
                                {showGoldMining && <GoldMining />} 
                            </div>
                        </div>
                        <div className="mineAction" >    
                            <BtnMining 
                                initialSeconds={detail.watering_count_down}  
                                className={`mineCoundown ${showGoldMining ? 'disabled' : ''}`}
                                onClick={function() {
                                    startMining();
                                }} 
                            /> 
                        </div>
                    </div> 
            </div>
        </span>  
    );
}

export default AssetWater;