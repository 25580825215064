import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalBox from './ModalBox' 

import assets from '../../assets.json';

const PurchaseMinerModal = ({
  isOpen,
  handleClose,
  title,
  onSubmit, 
  minerQty,
  handleMinusClick,
  handleAddClick,  
  canSubmit, 
  icon, 
  children,
}) => {
  const { t } = useTranslation(); 

  return (
    <ModalBox
      isOpen={isOpen}
      handleClose={handleClose}
      title={t(title)}
    >
      <div className="transfer-form withdrawal-form">
        <form className="forms" onSubmit={onSubmit} method="POST" autoComplete="off">
          <div className="redeem-content-body">
            <div className="text-center mt-4">
              <img className="bar-coin-icon" src={icon} height={60} alt="miner"  /> 
            </div>
            <div className="mine-item-form mt-4">
                <span
                    className={`btn-action btn-minus ${minerQty === 1 ? 'inactive' : ''}`}
                    onClick={handleMinusClick}
                >
                    <img src={assets.BTN_PM} width="40" alt="button"  />
                    <span>-</span>
                </span>
                <span className="mineQty" style={{ backgroundImage: `url(${assets.TEXT_INPUT_BG})` }}>
                    {minerQty}
                </span>
                <span className="btn-action btn-add" onClick={handleAddClick}>
                    <img src={assets.BTN_PM} width="40"  alt="button" />
                    <span>+</span>
                </span>
            </div>
 
          </div>
          <div className="transfer-footer">
            <button className="btnSubmit" disabled={!canSubmit}>
              <img src={assets.SUBMIT_BTN} width={100}  alt="button"  />
              <span className="btn_redeem">{minerQty} <img className="bar-coin-icon"  src={assets.USDT_BTN} height={22} alt="usdt"  /></span>
            </button>
          </div>
        </form>
        {children}
      </div>
    </ModalBox>
  );
};

export default PurchaseMinerModal;