import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css'; // Import Bootstrap CSS
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import unregisterServiceWorker from './serviceWorkerDev'; // Import the service worker unregister function

import App from './components/App/App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

serviceWorkerRegistration.register();
 
// if (process.env.NODE_ENV === 'development') {
//   unregisterServiceWorker();
// }

unregisterServiceWorker();

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js').then((registration) => {
    registration.onupdatefound = () => {
      const installingWorker = registration.installing;
      installingWorker.onstatechange = () => {
        if (installingWorker.state === 'installed') {
          if (navigator.serviceWorker.controller) {
            // New content is available, notify user to refresh
            const notification = new Notification('New version available!', {
              body: 'Refresh the page to apply updates.',
            });
            notification.onclick = () => {
              window.location.reload();
            };
          }
        }
      };
    };
  });
}


reportWebVitals();
