// SettingsModal.js
import React, { useState } from 'react';
import { useSSR, useTranslation } from 'react-i18next';
import ModalBox from './ModalBox'  
import assets from '../../assets.json';

const SettingsModal = ({
  isOpen,
  handleClose,
  handleAudioCheckboxChange,
  audioCheckboxState,
  lang,
  handleUpdateLang,
  languages, 
}) => {
    const { t } = useTranslation();
    const [ bgMusic, setBgMusic ] = useState(localStorage.getItem('bgMusic') ?? 'bg');
   
    const musics = [
      { value: "bg", label: t("Music")+" 1" },
      { value: "bg2", label: t("Music")+" 2" },
      { value: "bg3", label: t("Music")+" 3" },
      { value: "bg4", label: t("Music")+" 4" },
      { value: "bg5", label: t("Music")+" 5" },
  ]; 

  const setLocalBgMusic = (value) => {  
    localStorage.setItem('bgMusic', value);
    setBgMusic(value)
  }


  return (
    <ModalBox
      isOpen={isOpen}
      handleClose={handleClose}
      title={t("Settings")}
    >
      <div className="modal-notif-content">
        <div className="form-group input-music">
          <label>{t("Music")}</label>
          <input
            type="checkbox"
            value={1}
            onChange={handleAudioCheckboxChange}
            checked={audioCheckboxState === "1"}
            className={audioCheckboxState === "1" ? 'checked fa-solid fa-check' : ''}
          />
        </div>
 

        <select
          value={lang}
          onChange={handleUpdateLang}
          className="filter-dropdown"
          style={{ backgroundImage: `url('${assets.TEXT_INPUT_BG}')` }}
        >
          {languages.map((language) => (
            <option key={language.value} value={language.value}>
              {language.label}
            </option>
          ))}
        </select>
      </div>
    </ModalBox>
  );
};

export default SettingsModal;