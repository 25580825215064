import { createContext, useState, useEffect } from 'react';

const AudioContext = createContext();

const AudioProvider = ({ children }) => {
  const [audio, setAudio] = useState(null);
  const bgMusicFromLocalStorage = localStorage.getItem('bgMusic');
  const [audioCheckboxState, setAudioCheckboxState] = useState(localStorage.getItem('audio'));
  const [bgMusic, setBgMusic] = useState(bgMusicFromLocalStorage ? bgMusicFromLocalStorage : "bg");

  useEffect(() => {
    if (audio) {
      audio.currentTime = 0;
      audio.pause();   
    }
    
    if (audio && audioCheckboxState === "0") { 
      audio.pause();   
    } else {

      let bgMusic2 = localStorage.getItem('bgMusic') 
      if(bgMusic2 === null){
        bgMusic2 = "bg"
      } 
      const audioElement = new Audio(`audio/bg5.mp3`);
      audioElement.loop = true; 
      audioElement.currentTime = 0;
      setAudio(audioElement);
    }
  }, [bgMusic, audioCheckboxState]);

  useEffect(() => { 
    const handleStorageChange = () => {
      const newBgMusic = localStorage.getItem('bgMusic'); 
      if (newBgMusic !== bgMusic) {
        setBgMusic(newBgMusic);
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [bgMusic]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newBgMusic = localStorage.getItem('bgMusic');
      if (newBgMusic !== bgMusic) {
        setBgMusic(newBgMusic);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [bgMusic]);

  return (
    <AudioContext.Provider value={{ audio }}>
      {children}
    </AudioContext.Provider>
  );
};

export { AudioProvider, AudioContext };