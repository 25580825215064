import CryptoJS from "crypto-js"; 
import md5 from 'md5';
import json  from 'json-keys-sort'; 


export const doSomethingWithInput = (theInput) => {
   //Do something with the input
   return theInput;
};
 
const secretKEY = process.env.REACT_APP_AESKEY;  
const secretIV = process.env.REACT_APP_AESIV;  
const signKey = process.env.REACT_APP_SIGNKEY;  
 
export const encryptData = (postJson, suffix) => {
    let IV1 = secretIV+suffix
    let IV = CryptoJS.enc.Utf8.parse(IV1);
    let key1 = CryptoJS.enc.Utf8.parse(secretKEY); 
    var postdata = JSON.stringify(postJson)  
    let encrypted = CryptoJS.AES.encrypt(postdata, key1, { iv: IV, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString(); 
};

export const decryptData = (postdata, suffix) => {
    let IV1 = secretIV+suffix
    let IV = CryptoJS.enc.Utf8.parse(IV1);
    let key1 = CryptoJS.enc.Utf8.parse(secretKEY);  
    let decrypted = CryptoJS.AES.decrypt(postdata, key1, { iv: IV, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }); 
    return decrypted.toString(CryptoJS.enc.Utf8)
};

export const genSign = (postJson) => { 
    var sign = ""; 
    postJson = json.sort(postJson, true) 
    Object.entries(postJson).forEach((entry) => {
        const [key, value] = entry; 
        sign += key+"="+value+"&"
    });
    
    sign = sign + signKey;  
    return md5(sign)
}
export function logs(message) {
    if(process.env.REACT_APP_ENV === 'dev')
    {
        console.log(message);
    } 
} 

export const signature = (member_id, token, timeStr) => { 
    const idToken = `${member_id}${token}`;
    const idTokenHash = md5(idToken);
    const sign = `${timeStr}${token}${idTokenHash}`;
    const signHash = md5(sign);
  
    return signHash;
};

export const parseMessage = (returnString, params) => {
    let formattedString = returnString;
  
    for (const [key, value] of Object.entries(params)) {
      formattedString = formattedString.replace(`${key}`, value);
    }
  
    return formattedString;
}

export const parseLog = (returnString) => {
    let params1 = {
        "<color=##money_trans##>": "<span style='color:#22aa09'>",
        "</color>": "</span>",
    };
    let res1 = parseMessage(returnString, params1) 
    let params2 = {
        "##bonus##": "<span class='log-category'>BONUS</span>", 
        "##usdt##": "<span class='log-category'>USDT</span>",  
        "##miner##": "<span class='log-category'>miner</span>",  
        "##point##": "<span class='log-category'>point</span>",  
    };
    return parseMessage(res1, params2) 
}
export const maskEmail = (email) => {  
    const [localPart, domain] = email.split('@');
    const maskedLocalPart = localPart.substring(0, 2) + 'x'.repeat(localPart.length - 2);
    const maskedDomain = domain.substring(0, 2) + 'x'.repeat(domain.length - 2);
    return `${maskedLocalPart}@${maskedDomain}`;
}

export const setLocalStorageItems = (memberDetails) => {
    if (localStorage.getItem('memberDetails')) { 
        localStorage.removeItem('memberDetails');
    } 
    localStorage.setItem('memberDetails', JSON.stringify(memberDetails));  
    localStorage.setItem('username', memberDetails.username);  
    localStorage.setItem('email', memberDetails.email);  
    localStorage.setItem('member_id', memberDetails.member_id);  
    localStorage.setItem('refferral_code', memberDetails.refferral_code);  
    localStorage.setItem('referral_qr_code', memberDetails.referral_qr_code);  
    localStorage.setItem('miner', memberDetails.wallet.miner);  
    localStorage.setItem('bonus', memberDetails.wallet.bonus);  
    localStorage.setItem('usdt', memberDetails.wallet.usdt);  
    localStorage.setItem('point', memberDetails.wallet.point);  
    localStorage.setItem('audio', memberDetails.audio);  

    localStorage.setItem('lang', memberDetails.language);  

};
   
export const updateSlotLocalStorage = (slot) => { 
    localStorage.setItem('slot',  JSON.stringify(slot));   
};
   
export const updateWalletLocalStorage = (wallet) => { 
    localStorage.setItem('miner', wallet.miner);  
    localStorage.setItem('bonus', wallet.bonus);  
    localStorage.setItem('usdt', wallet.usdt);  
    localStorage.setItem('point', wallet.point);  
};
export const getValue = (key, defaultValue = null) => {
    // Retrieve the member details JSON string from localStorage
    const memberDetailsJson = localStorage.getItem('memberDetails');
    
    // If no data is found, return the default value
    if (!memberDetailsJson) {
        return defaultValue;
    }
    
    try {
        // Parse the JSON string into an object
        const memberDetails = JSON.parse(memberDetailsJson);
        
        // Return the value associated with the provided key, or the default value if the key does not exist
        return memberDetails[key] !== undefined ? memberDetails[key] : defaultValue;
    } catch (error) {
        // Log an error if JSON parsing fails
        console.error("Failed to parse member details from localStorage:", error);
        return defaultValue;
    }
}
 
export const  uuBalance = () => {
    const updatedValues = {};
    try {
        const bonus = localStorage.getItem('bonus');
        if (bonus) {
          updatedValues.bonus = parseFloat(bonus).toFixed(2);
        }
    
        const usdt = localStorage.getItem('usdt');
        if (usdt) { 
          updatedValues.usdt = parseFloat(usdt).toFixed(2);
        }
    
        const miner = localStorage.getItem('miner');
        if (miner) { 
          updatedValues.miner = parseFloat(miner).toFixed(0);
        }
    
        const point = localStorage.getItem('point');
        if (point) { 
          updatedValues.point = parseFloat(point).toFixed(2);
        } 
      } catch (error) {
      console.error('Error updating balance:', error);
    }

    return updatedValues;
};
