import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalBox from './ModalBox'  
import assets from '../../assets.json';

const WithdrawalConfirmModal = ({
  isOpen,
  handleClose,
  withdrawalAmount,
  withdrawalFee,
  withdrawalFinal,
  handleConfirmWithdrawalButton,
}) => {
    const { t } = useTranslation();

  return (
    <ModalBox
      isOpen={isOpen}
      handleClose={handleClose}
      title={t("Withdrawal")}
    >
      <div className="modal-notif-content">
        <h4>{t("Withdrawal Balance")}</h4>
        <div className="withdrawal-detail">
          <div className="withdrawal-detail-row">
            <span className="row-title">{t("Withdrawal Amount")}</span>
            <span className="row-value">
              {withdrawalAmount}
              <img className="bar-coin-icon" src={assets.USDT_BTN} height={22} title="coin" />
            </span>
          </div>
          <div className="withdrawal-detail-row">
            <span className="row-title">{t("Processing Fee")}</span>
            <span className="row-value">
              -{withdrawalFee}
              <img className="bar-coin-icon" src={assets.USDT_BTN} height={22} title="coin"  />
            </span>
          </div>
          <div className="withdrawal-detail-row withdrawal-total">
            <span className="row-title">{t("Total")}</span>
            <span className="row-value">
              {withdrawalFinal}
              <img className="bar-coin-icon" src={assets.USDT_BTN} height={22} title="coin"  />
            </span>
          </div>

          <button className="btnSubmit mt-4" onClick={handleConfirmWithdrawalButton}>
            <img src={assets.SUBMIT_BTN} width={100}  title="button" />
            <span>{t("Confirm")}</span>
          </button>
        </div>
      </div>
    </ModalBox>
  );
};

export default WithdrawalConfirmModal;