import React, {useEffect,useState,useRef } from 'react';

import { useNavigate } from "react-router-dom"; 
import { useTranslation } from 'react-i18next'; 
import useLocalStorage from './useLocalStorage';
import { authApiCall , createApiCall }  from './createApiCall'
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import ModalService from './ModalService'; 
import { parseMessage,  setLocalStorageItems, updateWalletLocalStorage, uuBalance, updateSlotLocalStorage } from '../../Services/Utils'  

import WalletModal from './WalletModal' 
import RedeemModal from './RedeemModal' 
import PurchaseMinerModal from './PurchaseMinerModal';
import WithdrawalFormModal from './WithdrawalFormModal';
import RedeemMinerModal from './RedeemMinerModal' 
import WithdrawalConfirmModal from './WithdrawalConfirmModal';
import TransactionPasswordModal from './TransactionPasswordModal';

import assets from '../../assets.json';

export default function DashboardHeader(props) {
    const inputRef  = useRef(null);
    const navigate  = useNavigate();
    const { t }     = useTranslation();
    const { i18n } = useTranslation();  
 
    const [usdtModal, setUsdtModal] = useState(false);   
    const [minerModal, setMinerModal] = useState(false);   
    const [bonusModal, setBonusModal] = useState(false);    
    const [withdrawalModal, setWithdrawalModal] = useState(false);   
    const [withdrawalConfirmModal, setWithdrawalConfirmModal] = useState(false);  
    const [passwordModal, setPasswordModal] = useState(false);
    const [otpType, setOtpType] = useState(null);   
    const [canSubmitPassword, setCanSubmitPassword] = useState(false);  
 
    const [time] = useState(10);
    const [canSubmitOtp, setCanSubmitOtp] = useState(false);
    const [sendOtp, setSendOtp] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [withdrawalAmount, setWithdrawalAmount] = useState(0);
    const [withdrawalFee, setWithdrawalFee] = useState(0);
    const [withdrawalFinal, setWithdrawalFinal] = useState(0);

    const [redeemProgress, setRedeemProgress] = useState(0);
    const [canSubmitRedeem, setCanSubmitRedeem] = useState(false);
    const [transactionPassword, setTransactionPassword] = useState("");
 
    const [amountError, setAmountError] = useState("");
    const [wMinAmount, setWMinAmount] = useState(0);
    const [wMaxAmount, setWMaxAmount] = useState(0);
    const [walletAddress, setWalletAddress] = useState("");
    const [canConfirmWithdrawal, setCanConfirmWithdrawal] = useState(false);
    const [transactionPasswordFormType, setTransactionPasswordFormType] = useState(null);
    const [minerQty, setMinerQty] = useState(0);
    const [minerError, setMinerError] = useState(null);
    const [canConfirmMiner, setCanConfirmMiner] = useState(false);
    const [transactionPasswordOtp, setTransactionPasswordOtp] = useState(null);  
    const [redeemInfo, setRedeemInfo ] = useState([]);
   
    const [email] = useLocalStorage('email', "test@test.com");
    const [total_bonus, setTotal_bonus] = useState(localStorage.getItem('bonus') || 0);
    const [total_usdt, setTotal_usdt] = useState(localStorage.getItem('usdt') || 0);
    const [total_miner, setTotal_miner] = useState(localStorage.getItem('miner') || 0);
    const [total_point, setTotal_point] = useState(localStorage.getItem('point') || 0);
         
    const  updateBalance = () => { 
        const updatedValues = uuBalance(); 
        
        if (updatedValues) {
            if (updatedValues.bonus) {
                setTotal_bonus(updatedValues.bonus);
            }
            if (updatedValues.usdt) {
                setTotal_usdt(updatedValues.usdt);
            }
            if (updatedValues.miner) {
                setTotal_miner(updatedValues.miner);
            }
            if (updatedValues.point) {
                setTotal_point(updatedValues.point);
            }
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            updateBalance()
        }, 1000); // 10 seconds
    
        return () => {
          clearInterval(intervalId);
        };
    }, []);
     


    const storedSettings = JSON.parse(localStorage.getItem('gameSetting'));  
    const memberDetails = JSON.parse(localStorage.getItem('memberDetails'));

    const getSettingByKey = (key) => {
        if(storedSettings){ 
            const setting = storedSettings.find(item => item.key === key);
            return setting ? setting.value : null; 
         }
         return null;
     };

    const getMemberByKey = (key) => {
        return memberDetails && memberDetails[key] ? memberDetails[key] : null;
    };

    let trc20_wallet_address = getMemberByKey("trc20_wallet_address") || null;   
  
    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value) 
    }     
    
    const copyToClipboard  = (email, type) => {  
        const textArea = document.createElement('textarea');
        textArea.value = email; 
        document.body.appendChild(textArea); 
        textArea.select();
        document.execCommand('copy'); 
        document.body.removeChild(textArea); 
        Notify.success(parseMessage(t("%type have copy to clipboard"),{"%type": t(type)}));   
    }; 

    const loadSetting =  async () => {
        const apiCall = createApiCall("login", {}, (response) => { 
            let member_details= response.data.member_details 
            setLocalStorageItems(member_details);
            
            const updatedValues = updateBalance();
            if (updatedValues) {
                if (updatedValues.bonus) {
                    setTotal_bonus(updatedValues.bonus);
                }
                if (updatedValues.usdt) {
                    setTotal_usdt(updatedValues.usdt);
                }
                if (updatedValues.miner) {
                    setTotal_miner(updatedValues.miner);
                }
                if (updatedValues.point) {
                    setTotal_point(updatedValues.point);
                }
            }
            let newlang = member_details.language; 
            i18n.changeLanguage(newlang); 

            gameSetting();   
            fetchRedeemInfo()  
        })
        await apiCall(navigate, t);
    };
    
    useEffect(() => {  
        loadSetting() 
    }, []); 
   
    useEffect(() => {   
        if(redeemInfo.value){
            let progress = parseFloat(total_bonus)/parseFloat(redeemInfo.value)*100;
            if(progress > 100){
                progress = 100;
            }
            setRedeemProgress(progress); 
            
            if(redeemInfo.redeem_count < redeemInfo.maximum_redeem && total_bonus >= redeemInfo.value){ 
                setCanSubmitRedeem(true);
            } else {
                setCanSubmitRedeem(false);
            } 
        }

    }, [redeemInfo, total_bonus]); 

    useEffect(() => { 
        if(transactionPassword){
            setCanSubmitPassword(true);
        }else{
            setCanSubmitPassword(false);
        } 
    }, [transactionPassword]); 
     
    useEffect(() => {
        if (sendOtp) {
            const timer = setTimeout(() => {
            setSendOtp(false);
            }, ((time+1) * 1000)); // 15 seconds

            return () => {
                clearTimeout(timer);
            };
        }
    }, [sendOtp, time]);

    useEffect(() => {
        if (sendOtp) {
            const interval = setInterval(() => {
                setCountdown(countdown - 1);
            }, 1000); // 1 second 
            return () => {
                clearInterval(interval);
            };
        }
    }, [sendOtp, countdown]);
         
    useEffect(() => { 
        if(sendOtp === false){
            setCanSubmitOtp(true);
        }else{
            setCanSubmitOtp(false);
        } 
    }, [ sendOtp]); 
         
    useEffect(() => { 
        if( withdrawalModal ){ 
            setOtpType("withdrawal")
        }
        else{
            setOtpType(null)
        } 
    }, [ withdrawalModal ]); 
  
    useEffect(() => { 
       let withdrawalMinAmount = getSettingByKey("usdt_withdrawal_min") || 0; 
       let withdrawalMaxAmount = getSettingByKey("usdt_withdrawal_max") || 0;
       let usdt_withdrawal_charges = getSettingByKey("usdt_withdrawal_charges") || 0;
       setWMinAmount(withdrawalMinAmount);
       setWMaxAmount(withdrawalMaxAmount);
       setWithdrawalFee(usdt_withdrawal_charges);

        let withdrawalAmount2 = parseFloat(withdrawalAmount);
        let withdrawalMinAmount2 = parseFloat(withdrawalMinAmount);
        let withdrwalMaxAmount2 = parseFloat(withdrawalMaxAmount);
        if(withdrawalAmount2 < withdrawalMinAmount2){
            setCanConfirmWithdrawal(false);
            setAmountError(parseMessage(t("min withdrawal amount: %amount USDT"), {"%amount": withdrawalMinAmount2}));
        }
        else if(withdrawalAmount2 > withdrwalMaxAmount2){
            setCanConfirmWithdrawal(false);
            setAmountError(parseMessage(t("max withdrawal amount: %amount USDT"), {"%amount": withdrwalMaxAmount2 }));
        } 
        else{
            setAmountError("");
            if(transactionPasswordOtp && walletAddress){
                setCanConfirmWithdrawal(true);
            } else {
                setCanConfirmWithdrawal(false);
            }
        } 


       setWithdrawalFinal(withdrawalAmount - withdrawalFee);
        
    }, [withdrawalAmount, transactionPasswordOtp, walletAddress, withdrawalFee, t]); 
 
    useEffect(() => {  
        if(minerQty <= 0){
            setCanConfirmMiner(false);
            setMinerError(t("Min miner to purchase: 1"));
        } 
        else{
            let requestUsdt = minerQty * 1;
            setMinerError("");
            if( parseFloat(requestUsdt) > parseFloat(total_usdt) ){
                setMinerError(t("Balance not enough"));
                setCanConfirmMiner(false);
            } else {
                setCanConfirmMiner(true);
                setMinerError("");
            }
        }   
    }, [minerQty, total_usdt, t]); 
  
    const gameSetting =  async () => {
        const apiCall = createApiCall("game/setting", {}, (response) => {
            if(response.result === 1){    
                let gameSetting = response.setting;
                localStorage.setItem('gameSetting', JSON.stringify(gameSetting));  
            } else {   
                ModalService.showModal(<div>{t(response.message_code)}</div>,{type: "error"}); 
            }
        })
        await apiCall(navigate, t);
    };   

    const fetchRedeemInfo =  async () => {
        const apiCall = createApiCall("package/getRedeemInfo", {}, (response) => { 
            let data = response.redeemInfo;
            if (data.length > 0) {
              setRedeemInfo(data[0]);
            } 
        })
        await apiCall(navigate, t);
    }; 
   
    const sendOtpForm = async () => { 
        const apiCall = authApiCall("otp/request", {
            type: otpType, 
            email: email
        }, (response) => {
            if(response.result === 1){     
                setSendOtp(true) 
                setCountdown(time);
                Notify.success(t("OTP have send to your email"));   
            }
        });
        await apiCall(navigate, t);
    };
 
    const confirmWithdrawalFormSubmit = async (e) => { 
        e.preventDefault(); 
        if(parseFloat(withdrawalAmount) > parseFloat(total_usdt))
        { 
            Notify.failure(t("Not enought USDT to withdraw")); 
            return false;
        }
        setWithdrawalConfirmModal(true);
        setWithdrawalModal(false);
  
    };

    const handleConfirmWithdrawalButton = () => {
        setTransactionPasswordFormType("withdrawal");
        setPasswordModal(true);
    }

    const confirmPassword = () => {

        if(transactionPasswordFormType === "withdrawal"){
            handleConfirmWithdrawalFormSubmit()
        } else if(transactionPasswordFormType === "miner"){
            handleConfirmMinerFormSubmit()
        }
    }
 
    const handleConfirmWithdrawalFormSubmit = async () => {

        const apiCall = createApiCall("member/withdrawUsdt", {
            type: "trc20", 
            amount: withdrawalAmount,
            wallet_address: walletAddress, 
            transaction_password: transactionPassword,
            otp: transactionPasswordOtp,
        }, (response) => {
            if(response.result === 1){    
                ModalService.showModal(<div>{t("Withdrawal Successful!!")}</div>,{type: "happy"}); 
                setTransactionPasswordOtp(null); 
                setWalletAddress(null); 
                setWithdrawalAmount(null); 
                setTransactionPassword(false); 
                setWithdrawalConfirmModal(false); 
                setPasswordModal(false); 

                updateWalletLocalStorage(response.wallet);
                 
                const updatedValues = updateBalance();
                if (updatedValues) {
                    if (updatedValues.bonus) {
                        setTotal_bonus(updatedValues.bonus);
                    }
                    if (updatedValues.usdt) {
                        setTotal_usdt(updatedValues.usdt);
                    }
                    if (updatedValues.miner) {
                        setTotal_miner(updatedValues.miner);
                    }
                    if (updatedValues.point) {
                        setTotal_point(updatedValues.point);
                    }
                }

                inputRef.current.value = '';
            } else {  
                if(response.message_code === "401"){
                    ModalService.showModal(<div>{t("Wrong transaction password")}</div>,{type: "error"}); 
                } else {
                    ModalService.showModal(<div>{t(response.message_code)}</div>,{type: "error"}); 
                }
            }
        });
        await apiCall(navigate, t);
    };

    const handleConfirmMinerFormSubmit = async () => {

        const apiCall = createApiCall("wallet/topUpMiner", {
            amount: minerQty, 
            transaction_password: transactionPassword,
        }, (response) => { 
            if(response.result === 1){    
                ModalService.showModal(<div>{t("Purchase Successful!!")}</div>,{type: "happy"}); 
                let member_details= response.member 
                setLocalStorageItems(member_details);
                setPasswordModal(false);
                setMinerModal(false);
                inputRef.current.value = '';
            } else { 
                ModalService.showModal(<div>{t(response.message_code)}</div>,{type: "error"}); 
            }
        });
        await apiCall(navigate, t);
    };
 
    const handleWithdrawBtn = () => {
        setWithdrawalModal(true) 
        setUsdtModal(false) 
    }
     
    const confirmMinerForm = (e) => { 
        e.preventDefault();
        setTransactionPasswordFormType("miner");
        setPasswordModal(true);
    }
 
    const handleMinerMinusClick = () => {
        if (minerQty > 1) {
            setMinerQty(minerQty - 1);
        }
    };

    const handleMinerAddClick = () => {
        setMinerQty(minerQty + 1);
    };
 
 

    
       
    const confirmRedeemMine =  async (package_id) => {
        const apiCall = createApiCall("wallet/redeemMine", {
            package_id: package_id
        }, (response) => {
            if(response.result === 1){    
                ModalService.showModal(<div>{t("Redeem Successful!!")}</div>,{type: "excited"}); 
                         
                setBonusModal(false)
                if(response.slot){ 
                    updateSlotLocalStorage(response.slot);
                    updateSlot();
                }
                if(response.member){  
                    updateWalletLocalStorage(response.member.wallet);
                    updateBalance();
                }

                loadSetting() 
                fetchRedeemInfo()  
            } else {   
                ModalService.showModal(<div>{t(response.message_code)}</div>,{type: "error"}); 
            }
        })
        await apiCall(navigate, t);
    };
      
    const [canSubmitRedeem2, setCanSubmitRedeem2] = useState(false);
    const [pointModal, setPointModal] = useState(false); 
 
    const [slotDetail, setSlotDetail] = useState(JSON.parse(localStorage.getItem('slot') ?? '[]'));
    const updateSlot = () => {
        setSlotDetail(JSON.parse(localStorage.getItem('slot')));
    };

    const confirmRedeemMiner =  async (package_id) => {
        const apiCall = createApiCall("wallet/redeemMiner", {
            qty: redeemMinerQty
        }, (response) => {
            if(response.result === 1){    
                ModalService.showModal(<div>{t("Redeem Successful!!")}</div>,{type: "excited"}); 
                setPointModal(false)

                if(response.slot){ 
                    updateSlotLocalStorage(response.slot);
                    updateSlot();
                }
                if(response.member){  
                    updateWalletLocalStorage(response.member.wallet);
                    updateBalance();
                }
                
            } else {   
                ModalService.showModal(<div>{t(response.message_code)}</div>,{type: "error"}); 
            }
        })
        await apiCall(navigate, t);
    };
     
    const [ redeemMinerQty, setRedeemMinerQty ] = useState(0);
    const [ requiredMinerPoint, setRequiredMinerPoint ] = useState(0);
    const [ requiredUSDT, setRequiredUSDT ] = useState(0);
    const [ totalRedeemMiner, setTotalRedeemMiner ] = useState(0);
    const [ minerRedeemError, setMinerRedeemError ] = useState(null);

    const handleMinusClick = () => {
        if (redeemMinerQty > 1) {
            setRedeemMinerQty(redeemMinerQty - 1);
        }
    };

    const handleAddClick = () => {
        setRedeemMinerQty(redeemMinerQty + 1);
    };

    useEffect(() => { 
        setRequiredMinerPoint(redeemMinerQty);
        setRequiredUSDT(redeemMinerQty); 

    },[redeemMinerQty]);

    useEffect(() => {  
        let minerRedeemError2 = "";
        if((total_usdt>= requiredUSDT && total_point > requiredMinerPoint)){  
            setCanSubmitRedeem2(true); 
        } else { 
            setCanSubmitRedeem2(false); 
        } 
        
        if(total_usdt < requiredUSDT ){
            minerRedeemError2 += t("not enought usdt")+"\n"; 
        }
        if(total_point < requiredMinerPoint ){
            minerRedeemError2 += t("not enought miner point")+"\n"; 
        }
        
        if(minerRedeemError2 !== ""){
            setMinerRedeemError(minerRedeemError2);
        }
        let totalRedeemMiner2 = (parseFloat(requiredUSDT) + parseFloat(requiredMinerPoint)) ;
        setTotalRedeemMiner(totalRedeemMiner2);

    },[requiredMinerPoint, requiredUSDT, total_point, total_usdt, t]);
 
    return (
        <div className="dashboard-header"
            style={{ backgroundImage: `url(${assets.BOTTOM_BAR})` }}
            >   
            <div className="dashboard-header-bar"> 
                <span className="bar-coin btn-usdt" onClick={ () => setUsdtModal(true) } > 
                    <img className="bar-coin-icon" src={assets.USDT_BTN} height={45} alt="usdt" />
                    <img className="bar-coin-bg" src={assets.BAR_COIN_AMOUNT}   height={40} alt="coin"  />
                    <span className="bar-coin-input">{total_usdt}</span>
                </span>
                <span className="bar-coin btn-miner" onClick={ () => setMinerModal(true) } > 
                    <img className="bar-coin-icon" src={assets.MINER_BTN} height={45} alt="usdt" />
                    <img className="bar-coin-bg" src={assets.BAR_COIN_AMOUNT}  height={40}  alt="coin" />
                    <span className="bar-coin-input">{total_miner}</span>
                </span>
                <span className="bar-coin btn-bonus" onClick={ () => setBonusModal(true) } > 
                    <img className="bar-coin-icon" src={assets.COIN_BONUS} height={45} alt="usdt" />
                    <img className="bar-coin-bg" src={assets.BAR_COIN_AMOUNT}   height={40} alt="coin"  />
                    <span className="bar-coin-input">{total_bonus}</span>
                </span>
                
                <span className="bar-coin btn-bonus" onClick={ () => setPointModal(true) } > 
                    <img className="bar-coin-icon"  src={assets.COIN_POINT}  height={45} alt="usdt" />
                    <img className="bar-coin-bg"  src={assets.BAR_COIN_AMOUNT}   height={40} alt="coin"  />
                    <span className="bar-coin-input">{total_point}</span>
                </span>
                
            </div> 
 
            {/*setBonusModal */} 
            <RedeemModal
                isOpen={bonusModal}
                handleClose={() => setBonusModal(false)}
                title="Redeem Bonus"
                redeemInfo={redeemInfo}
                redeemProgress={redeemProgress}
                totalBonus={total_bonus}
                canSubmitRedeem={canSubmitRedeem}
                onRedeemClick={() => confirmRedeemMine(redeemInfo.package_id)}
                silverStarterIcon={assets.SILVERSTARTER}
                goldStarterIcon={assets.GOLDSTARTER} 
            > 
            </RedeemModal>
 
            {/*setPointModal */} 
            <RedeemMinerModal
                isOpen={pointModal}
                handleClose={() => setPointModal(false)}
                title="Redeem Bonus"
                redeemInfo={redeemInfo} 
                totalPoint={total_point}
                totalUsdt={total_usdt} 
                canSubmitRedeem={canSubmitRedeem2}
                totalRedeemMiner={totalRedeemMiner}
                redeemMinerQty={redeemMinerQty}
                handleMinusClick={handleMinusClick}
                handleAddClick={handleAddClick}
                requiredMinerPoint={requiredMinerPoint}
                requiredUSDT={requiredUSDT}
                minerRedeemError={minerRedeemError}
                onRedeemClick={() => confirmRedeemMiner(redeemInfo.package_id)}
                silverStarterIcon={assets.SILVERSTARTER}
                goldStarterIcon={assets.GOLDSTARTER} 
            > 
            </RedeemMinerModal>
  
            {/*setMinerModal */} 
            <PurchaseMinerModal
                isOpen={minerModal}
                handleClose={() => setMinerModal(false)}
                title="Purchase Miner"
                onSubmit={confirmMinerForm}
           
                minerQty={minerQty}
                handleMinusClick={handleMinerMinusClick}
                handleAddClick={handleMinerAddClick}
                
                error={minerError}
                canSubmit={canConfirmMiner} 
                icon={assets.MINER_BTN}
                inputBg={assets.PASSWORD_INPUT_BG}
            > 
            </PurchaseMinerModal> 
   
            {/*setUsdtModal */} 
            <WalletModal
                isOpen={usdtModal}
                handleClose={() => setUsdtModal(false)}
                title="USDT Balance" 
                walletAddress={trc20_wallet_address}
                disabled={true}
                onCopyClick={() => copyToClipboard(trc20_wallet_address, "TRC 20 Wallet address")}
                onWithdrawClick={handleWithdrawBtn}
                > 
            </WalletModal>
 
            {/*setWithdrawalModal */} 
            <WithdrawalFormModal
                isOpen={withdrawalModal}
                handleClose={() => setWithdrawalModal(false)}
                title="Withdrawal"
                onSubmit={confirmWithdrawalFormSubmit}
                onChangeWithdrawalAmount={(e) => inputChangeHandler(setWithdrawalAmount, e)}
                onChangeWalletAddress={(e) => inputChangeHandler(setWalletAddress, e)}
                onChangeOTP={(e) => inputChangeHandler(setTransactionPasswordOtp, e)} 
                inputRef={inputRef}
                minValue={wMinAmount}
                maxValue={wMaxAmount}
                error={amountError}
                canSubmit={canConfirmWithdrawal} 
                canSubmitOtp={canSubmitOtp} 
                icon={assets.USDT_BTN}
                inputBg={assets.PASSWORD_INPUT_BG}
                total_usdt={total_usdt}
                sendOtpForm={sendOtpForm}
                countdown={countdown}
                withdrawalFee={withdrawalFee}
            >
            </WithdrawalFormModal>

  
            {/*setWithdrawalConfirmModal */}
            <WithdrawalConfirmModal
                isOpen={withdrawalConfirmModal}
                handleClose={() => {
                    setWithdrawalConfirmModal(false);
                    setWithdrawalModal(true);
                }}
                withdrawalAmount={withdrawalAmount}
                withdrawalFee={withdrawalFee}
                withdrawalFinal={withdrawalFinal}
                handleConfirmWithdrawalButton={handleConfirmWithdrawalButton}
            />

            {/*transaction password modal */} 
            <TransactionPasswordModal
                isOpen={passwordModal}
                handleClose={() => setPasswordModal(false)}
                inputChangeHandler={inputChangeHandler}
                setTransactionPassword={setTransactionPassword}
                canSubmitPassword={canSubmitPassword}
                confirmPassword={confirmPassword}
            /> 
        </div> 
      );
} 