import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalBox from './ModalBox' 
import { Progress } from 'react-vant';
 
import assets from '../../assets.json';

const RedeemMinerModal = ({
  isOpen,
  handleClose,
  title,
  redeemInfo,
  redeemProgress1,
  redeemProgress2,
  totalPoint,
  totalUsdt,
  puValue,
  totalRedeemMiner,
  canSubmitRedeem,
  onRedeemClick,
  silverStarterIcon,
  goldStarterIcon, 
  children,
  redeemMinerQty,
  handleAddClick,
  handleMinusClick,
  requiredMinerPoint,
  minerRedeemError,
  requiredUSDT
}) => {
  const { t } = useTranslation();
 
  return (
    <ModalBox
      isOpen={isOpen}
      handleClose={handleClose}
      title={t(title)}
    >
      <div className="redeem-content">
        {redeemInfo ? (
          <>
            <div className="redeem-content-body">
                <>
                  <img src={assets.MINER_BTN} width={60} alt="miner"  /> 
                </> 
                
                <div className="mine-item-form mt-4">
                    <span
                        className={`btn-action btn-minus ${redeemMinerQty === 1 ? 'inactive' : ''}`}
                        onClick={handleMinusClick}
                    >
                        <img src={assets.BTN_PM} width="40" alt="button"  />
                        <span>-</span>
                    </span>
                    <span className="mineQty" style={{ backgroundImage: `url(${assets.TEXT_INPUT_BG})` }}>
                        {redeemMinerQty}
                    </span>
                    <span className="btn-action btn-add" onClick={handleAddClick}>
                        <img src={assets.BTN_PM} width="40"  alt="button" />
                        <span>+</span>
                    </span>
                </div>
 
                
              <div className="redeem-process mt-3">
                <div className="redeem-process-title">{t("required_miner_point")}{" "}{requiredMinerPoint}<img src={assets.COIN_POINT} height={22} alt="point" /></div> 
                <div className="redeem-process-title">{t("required_usdt")}{" "}{requiredUSDT}<img src={assets.USDT_BTN} height={22} alt="usdt" /></div> 
              </div>
              {minerRedeemError && (
                <div className="text-alert mt-2">{minerRedeemError}</div>
              )}
            
            </div>
 
            <div className="redeem-content-footer"> 
              <button
                className="btnSubmit"
                onClick={onRedeemClick}
                disabled={!canSubmitRedeem}
              >
                <img src={assets.SUBMIT_BTN} width={100} alt="submit" />
                <span className="btn_redeem">{t("Redeem")} {totalRedeemMiner} <img className="bar-coin-icon" src={assets.MINER_BTN} height="22" alt="miner"  />`</span>
              </button>
            </div>
          </>
        ) : (
          <p>Loading...</p>
        )}
        {children}
      </div>
    </ModalBox>
  );
};

export default RedeemMinerModal;