import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalBox from './ModalBox'  
import assets from '../../assets.json';

const SubAccountModal = ({
  isOpen,
  handleClose,
  subaccountLists,
  onOpenSubAcconuntClick,
  canOpenSubaccount,
  email,
  username,
  rankImages,
  rankTitle,
  swapAccount,
  openSubaccount,
  MemberStatus,
  BadgeActive,
}) => {
  const { t } = useTranslation();

  return (
    <ModalBox
      isOpen={isOpen}
      handleClose={handleClose}
      title={t("Sub Account")}
      className={"modal-large"}
    >
      <div className="modal-notif-content">
        <div className="list-members">
          {subaccountLists.length > 0 && (
            subaccountLists.map((user, index) => (
              <div
                className="member-card"
                key={index}
                title={index + 1}
                style={{ backgroundImage: `url('${assets.CARDBGB3}')` }}
              >
                <div className="member-card-left">
                  <div className="member-card-rank">
                    <img src={rankImages[user.rank]} width={50} alt={t("rank")} />
                    <h4>{rankTitle[user.rank]}</h4>
                  </div>
                </div>

                <div className="member-card-right">
                  <h4>
                    {user.username === username ? (
                      <span>{user.username}</span>
                    ) : (
                      <span onClick={() => swapAccount(user.username)}>
                        {user.username}
                      </span>
                    )}
                    <MemberStatus status={user.status} />
                    {user.username === username && <BadgeActive>{t("USING")}</BadgeActive>}
                  </h4>

                  <div className="member-card-detail">
                    <div className="member-card-info">
                      <div>
                        <img
                          className="shortcut-btn-icon"
                          src={assets.USDT_BTN}
                          height={15}
                          title="usdt"
                        />: {user.wallet.usdt}
                      </div>
                      <div>
                        <img
                          className="shortcut-btn-icon"
                          src={assets.MINER_BTN}
                          height={15}
                          title="miner"
                        />: {user.wallet.miner}
                      </div>
                      <div>
                        <img
                          className="shortcut-btn-icon"
                          src={assets.COIN_BONUS}
                          height={15}
                          title="bonus"
                        />: {user.wallet.bonus}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      <div className="redeem-content-footer">
        <button
          className="btnSubmit"
          onClick={() => onOpenSubAcconuntClick()}
          disabled={!canOpenSubaccount}
        >
          <img src={assets.SUBMIT_BTN} width={100} alt="submit" />
          <span className="btn_add">{t("+")}</span>
        </button>
      </div>
    </ModalBox>
  );
};

export default SubAccountModal;