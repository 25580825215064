import React, { useState, useEffect } from 'react';

import ReactDOM from 'react-dom';

import assets from '../../assets.json';

const Modal = ({ onClose, children, callback = null, type="error", className=null }) => {
    const [isVisible, setIsVisible] = useState(false);
    const modalRoot = document.getElementById('modal-root');

    useEffect(() => {
        // Delay the visibility by 1 second
        const timeoutId = setTimeout(() => {
          setIsVisible(true);
        }, 1000);
    
        // Cleanup on unmount
        return () => {
          clearTimeout(timeoutId);
        };
    }, []);
        
    const handleClose = () => {
        setIsVisible(false);
        setTimeout(() => {
          onClose();
          if (callback) {
            callback(); // Call the callback when the modal is hidden
          }
        }, 300); // Match this with the fade-out duration
    };
 
    const imageSources = {
        error: assets.ERROR,
        success: assets.SUCCESS,
        happy: assets.HAPPY,
        excited: assets.EXCITED,
        annoying: assets.ANNONYING,
    };
    
    const imageAltTexts = {
        error: 'Error',
        success: 'Success',
        happy: 'Happy',
        excited: 'excited',
        annoying: 'Annoying'
    };

    return ReactDOM.createPortal(
        <div className={`modal-overlay ${isVisible ? 'fade-in' : 'fade-out'} ${className}`} onClick={handleClose}>
            <div className="modal-wrapper">
                <div className="modal-content" 
                    onClick={(e) => e.stopPropagation()}  
                    style={{ backgroundImage: `url('${assets.BORDER_GEM_INFO}')` }}
                > 
                    <div className="modal-icon">
                        {type in imageSources && (
                            <img 
                                src={imageSources[type]} 
                                width={80} 
                                alt={imageAltTexts[type] || 'Icon'} 
                            />
                        )}
                    </div>
                    {children}
                </div>
                <span className="close-button" onClick={handleClose}>
                    <img src={assets.BTN_CLOSE} width={60}/>
                </span> 
            </div>
        </div>,
        modalRoot
    );
};

export default Modal;
