
import React, { useEffect, useState, useRef, useContext } from 'react';

import { useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next';

import { Progress } from 'react-vant';
import useLocalStorage from './useLocalStorage';
import { parseMessage, setLocalStorageItems, getValue, updateWalletLocalStorage, uuBalance, updateSlotLocalStorage } from '../../Services/Utils'
import Footer from './Footer'; 
import ModalService from './ModalService';
import { createApiCall, authApiCall } from './createApiCall'
import { Notify } from 'notiflix/build/notiflix-notify-aio'; 
import { AudioContext } from '../AudioContext';

import WalletModal from './WalletModal'
import RedeemModal from './RedeemModal'
import PurchaseMinerModal from './PurchaseMinerModal';
import WithdrawalFormModal from './WithdrawalFormModal';
import RedeemMinerModal from './RedeemMinerModal'
import SubAccountModal from './SubAccountModal';
import LogoutModal from './LogoutModal';
import SettingsModal from './SettingsModal';
import SecurityModal from './SecurityModal';
import ChangePasswordModal from './ChangePasswordModal';
import UpdateTransactionPasswordModal from './UpdateTransactionPasswordModal';
import WithdrawalConfirmModal from './WithdrawalConfirmModal';
import TransactionPasswordModal from './TransactionPasswordModal';
import ConfirmOpenSubAccount from './ConfirmOpenSubAccount';
 
import assets from '../../assets.json';
 
export default function Profile() {
    const inputRef = useRef(null);

    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const navigate = useNavigate();

    const storedSettings = JSON.parse(localStorage.getItem('gameSetting'));
    const memberDetails = JSON.parse(localStorage.getItem('memberDetails'));

    const getSettingByKey = (key) => {
        if (storedSettings) {
            const setting = storedSettings.find(item => item.key === key);
            return setting ? setting.value : null;
        }
        return null;
    };

    const getMemberByKey = (key) => {
        return memberDetails && memberDetails[key] ? memberDetails[key] : null;
    };

    const [redeemInfo, setRedeemInfo] = useState([]);

    const fetchRedeemInfo = async () => {
        const apiCall = createApiCall("package/getRedeemInfo", {}, (response) => {
            let data = response.redeemInfo;
            if (data.length > 0) {
                setRedeemInfo(data[0]);
            }
        })
        await apiCall(navigate, t);
    };

    useEffect(() => {
        loadSetting()
        summaryReport()
        fetchRedeemInfo()
        subAccountGet()
    }, []);

    const [username] = useLocalStorage('username', "test@test.com");
    const [email] = useLocalStorage('email', "test@test.com");
    const [referral] = useLocalStorage('refferral_code', "xxxxxx");
    const [total_bonus, setTotal_bonus] = useState(localStorage.getItem('bonus') || 0);
    const [total_usdt, setTotal_usdt] = useState(localStorage.getItem('usdt') || 0);
    const [total_miner, setTotal_miner] = useState(localStorage.getItem('miner') || 0);
    const [total_point, setTotal_point] = useState(localStorage.getItem('point') || 0);
    const [transactionPasswordOtp, setTransactionPasswordOtp] = useState(null);

    const updateBalance = () => {
        const updatedValues = uuBalance();
        if (updatedValues) {
            if (updatedValues.bonus) {
                setTotal_bonus(updatedValues.bonus);
            }
            if (updatedValues.usdt) {
                setTotal_usdt(updatedValues.usdt);
            }
            if (updatedValues.miner) {
                setTotal_miner(updatedValues.miner);
            }
            if (updatedValues.point) {
                setTotal_point(updatedValues.point);
            }
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            updateBalance()
        }, 1000); // 10 seconds

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const [mineProfit, setMineProfit] = useState(0);
    const [mineValue, setMineValue] = useState(0);
    const [teamReward, setTeamReward] = useState(0);

    const credit = getValue('credit', 0);
    const credit_progress = getValue('credit_progress', 0);
    const credit_up = getValue('credit_up', 0);

    const transaction = credit_progress + "/" + credit_up;
    const totalStars = 5;
    const onStars = Math.min(credit, totalStars);
    const offStars = totalStars - onStars;

    const onStarsArray = Array(onStars).fill(assets.STAR_ON);
    const offStarsArray = Array(offStars).fill(assets.STAR_OFF);

    const starsArray = [...onStarsArray, ...offStarsArray];

    const rank = getValue('rank', 0);

    const loadSetting = async () => {
        const apiCall = createApiCall("login", {}, (response) => {
            let member_details = response.data.member_details
            setLocalStorageItems(member_details);
            updateBalance();
        })
        await apiCall(navigate, t);
    };

    const summaryReport = async () => {
        const apiCall = createApiCall("transaction/summaryReport", {}, (response) => {
            let summary = response.data[0]

            setMineProfit(summary.totalMineProfit);
            setMineValue(summary.totalMineValue);
            setTeamReward(summary.totalTeamReward);
        })
        await apiCall(navigate, t);
    };

    const copyToClipboard = (email, type) => {
        // Create a new text area element
        const textArea = document.createElement('textarea');
        textArea.value = email;

        // Append the text area to the DOM
        document.body.appendChild(textArea);

        // Select and copy the text from the text area
        textArea.select();
        document.execCommand('copy');

        // Clean up
        document.body.removeChild(textArea);

        Notify.success(parseMessage(t("%type have copy to clipboard"), { "%type": t(type) }));
    };

    const rankImages = {
        0: assets.RANK_1,
        1: assets.RANK_2,
        2: assets.RANK_3,
        3: assets.RANK_4,
        4: assets.RANK_5,
        5: assets.RANK_6,
    };

    const rankTitle = {
        0: t("VIP 0"),
        1: t("VIP 1"),
        2: t("VIP 2"),
        3: t("VIP 3"),
        4: t("VIP 4"),
        5: t("VIP 5"),
    };

    const [settingModal, setSettingModal] = useState(false);
    const [subaccountModal, setSubaccountModal] = useState(false);
    const [securityModal, setSecurityModal] = useState(false);
    const [logoutModal, setLogoutModal] = useState(false);
    const [changeTransactionPasswordModal, setChangeTransactionPasswordModal] = useState(false);
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [lang, setLang] = useState(localStorage.getItem('lang') !== null ? localStorage.getItem('lang') : "en");

    const [confirmLoginPassword, setConfirmLoginPassword] = useState(null);
    const [currentLoginPassword, setCurrentLoginPassword] = useState(null);
    const [newLoginPassword, setNewLoginPassword] = useState(null);
    const [canSubmitChangePassword, setCanSubmitChangePassword] = useState(false);
    const [confirmTransactionPassword, setConfirmTransactionPassword] = useState(null);
    const [newTansactionPassword, setNewTansactionPassword] = useState(null);
    const [canSubmitChangeTrasactionPassword, setCanSubmitChangeTrasactionPassword] = useState(false);
    const [subaccountLists, setSubaccountLists] = useState([]);

    const MemberStatus = ({ status }) => (
        <span className="member-status">
            {status === "active" ? (
                <img src={assets.ICON_ACTIVE} width={15}  alt="status"  />
            ) : (
                <img src={assets.ICON_IN_ACTIVE} width={15}  alt="status"  />
            )}
        </span>
    );

    const BadgeActive = ({ children }) => (
        <span className="badge-active">{children}</span>
    );

    const imageSources = {
        error: assets.ERROR,
        success: assets.SUCCESS,
        happy: assets.HAPPY,
        excited: assets.EXCITED,
        annoying: assets.ANNONYING,
    };

    const imageAltTexts = {
        error: 'Error',
        success: 'Success',
        happy: 'Happy',
        excited: 'excited',
        annoying: 'Annoying'
    };

    const inputChangeHandler = (setFunction, event) => {
        setFunction(event.target.value)
    }

    const confirmLogout = () => {
        clearLocalStorage();
        navigate('/login');
    }

    const clearLocalStorage = () => {
        if (typeof window !== 'undefined') {
            localStorage.clear();
        }
    };

    const languages = [
        { value: "en", label: t("English") },
        { value: "zh", label: t("Chinese") },
        { value: "vi", label: t("Vietnamese") },
    ];

    const handleUpdateLang = (e) => {
        e.preventDefault();
        let newLang = e.target.value;
        setLang(newLang);
        i18n.changeLanguage(newLang);
        localStorage.setItem('lang', newLang);
        handleChangeLanguageFormSubmit(newLang)
    }

    const handleChangeLanguageFormSubmit = async (newLang) => {
        const apiCall = createApiCall("language/change", {
            language: newLang
        }, (response) => {
        });
        await apiCall(navigate, t);
    };

    const handleChangePasswordFormSubmit = async (e) => {

        e.preventDefault();
        if (confirmLoginPassword !== newLoginPassword) {
            Notify.failure(t("Invalid password confirmation"));
            return false;
        }

        const apiCall = createApiCall("password/login/change", {
            password_old: currentLoginPassword,
            password_new: newLoginPassword,
            password_new_confirmation: confirmLoginPassword
        }, (response) => {
            if (response.result === 1) {
                ModalService.showModal(<div>{t("Login Password Changed Successful!!")}</div>, { type: "happy" });
                setCurrentLoginPassword(null);
                setNewLoginPassword(null);
                setConfirmLoginPassword(null);
                setChangePasswordModal(false);
                setSecurityModal(false);
                inputRef.current.value = '';
            } else {
                ModalService.showModal(<div>{t(response.message_code)}</div>, { type: "error" });
            }
        });
        await apiCall(navigate, t);
    };

    const handleChangeTransactionPasswordFormSubmit = async (e) => {

        e.preventDefault();
        if (confirmTransactionPassword !== newTansactionPassword) {
            Notify.failure(t("Invalid password confirmation"));
            return false;
        }

        const apiCall = createApiCall("password/transaction/reset", {
            otp: transactionPasswordOtp,
            transaction_password: newTansactionPassword,
            transaction_password_confirmation: confirmTransactionPassword
        }, (response) => {
            if (response.result === 1) {
                ModalService.showModal(<div>{t("Transaction Password Changed Successful!!")}</div>, { type: "happy" });
                setTransactionPasswordOtp(null);
                setConfirmTransactionPassword(null);
                setNewTansactionPassword(null);
                setChangeTransactionPasswordModal(false);
                setSecurityModal(false);
                inputRef.current.value = '';
            } else {
                ModalService.showModal(<div>{t(response.message_code)}</div>, { type: "error" });
            }
        });
        await apiCall(navigate, t);
    };

    const subAccountGet = async () => {
        const apiCall = createApiCall("subAccount/get", {}, (response) => {
            setSubaccountLists(response.accounts);
        })
        await apiCall(navigate, t);
    };

    const swapAccount = (username) => {
        subAccountLogin(username);
    }

    const [ canOpenSubaccount, setCanOpenSubaccount ] = useState(false);
    const openSubaccount = () => {
        setCanOpenSubaccount(true);
    }
    const subAccountLogin = async (username) => {
        const apiCall = createApiCall("subAccountLogin", {
            username: username,
            source: "api",
        }, (response) => {
            clearLocalStorage();
            Notify.success(t("Successful login to account:") + username);
            localStorage.setItem('token', response.token);

            loadSetting()
            setTimeout(() => {
                navigate('/home');
            }, 1000);
        })
        await apiCall(navigate, t);
    };

    useEffect(() => {
        setCanSubmitChangePassword(
            confirmLoginPassword !== null &&
            currentLoginPassword !== null &&
            newLoginPassword !== null
        );
    }, [confirmLoginPassword, currentLoginPassword, newLoginPassword]);

    useEffect(() => {
        setCanSubmitChangeTrasactionPassword(
            transactionPasswordOtp !== null &&
            confirmTransactionPassword !== null &&
            newTansactionPassword !== null
        );
    }, [confirmTransactionPassword, transactionPasswordOtp, newTansactionPassword]);

    const [usdtModal, setUsdtModal] = useState(false);
    const [minerModal, setMinerModal] = useState(false);
    const [bonusModal, setBonusModal] = useState(false);
    const [withdrawalModal, setWithdrawalModal] = useState(false);
    const [withdrawalConfirmModal, setWithdrawalConfirmModal] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);
    const [otpType, setOtpType] = useState(null);
    const [canSubmitPassword, setCanSubmitPassword] = useState(false);

    const [time] = useState(10);
    const [canSubmitOtp, setCanSubmitOtp] = useState(false);
    const [sendOtp, setSendOtp] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [withdrawalAmount, setWithdrawalAmount] = useState(0);
    const [withdrawalFee, setWithdrawalFee] = useState(0);
    const [withdrawalFinal, setWithdrawalFinal] = useState(0);

    const [redeemProgress, setRedeemProgress] = useState(0);
    const [canSubmitRedeem, setCanSubmitRedeem] = useState(false);
    const [transactionPassword, setTransactionPassword] = useState("");

    const [amountError, setAmountError] = useState("");
    const [wMinAmount, setWMinAmount] = useState(0);
    const [wMaxAmount, setWMaxAmount] = useState(0);
    const [walletAddress, setWalletAddress] = useState(null);
    const [canConfirmWithdrawal, setCanConfirmWithdrawal] = useState(false);
    const [transactionPasswordFormType, setTransactionPasswordFormType] = useState(null);
    const [minerQty, setMinerQty] = useState(0);
    const [minerError, setMinerError] = useState(null);
    const [canConfirmMiner, setCanConfirmMiner] = useState(false);

    let trc20_wallet_address = getMemberByKey("trc20_wallet_address") || null; 
    useEffect(() => {
        if (redeemInfo.value) {
            let progress = parseFloat(total_bonus) / parseFloat(redeemInfo.value) * 100;
            if (progress > 100) {
                progress = 100;
            }
            setRedeemProgress(progress);

            if (redeemInfo.redeem_count < redeemInfo.maximum_redeem && total_bonus >= redeemInfo.value) {
                setCanSubmitRedeem(true);
            } else {
                setCanSubmitRedeem(false);
            }
        }

    }, [redeemInfo, total_bonus]);

    useEffect(() => {
        if (transactionPassword) {
            setCanSubmitPassword(true);
        } else {
            setCanSubmitPassword(false);
        }
    }, [transactionPassword]);

    useEffect(() => {
        if (sendOtp) {
            const timer = setTimeout(() => {
                setSendOtp(false);
            }, ((time + 1) * 1000)); // 15 seconds

            return () => {
                clearTimeout(timer);
            };
        }
    }, [sendOtp, time]);

    useEffect(() => {
        if (sendOtp) {
            const interval = setInterval(() => {
                setCountdown(countdown - 1);
            }, 1000); // 1 second 
            return () => {
                clearInterval(interval);
            };
        }
    }, [sendOtp, countdown]);

    useEffect(() => {
        if (sendOtp === false) {
            setCanSubmitOtp(true);
        } else {
            setCanSubmitOtp(false);
        }
    }, [sendOtp]);

    useEffect(() => {
        if (changeTransactionPasswordModal) {
            setOtpType("forgot_trans_password")
        }
        else if (withdrawalModal) {
            setOtpType("withdrawal")
        }
        else {
            setOtpType(null)
        }
    }, [changeTransactionPasswordModal, withdrawalModal]);

    useEffect(() => {
        let withdrawalMinAmount = getSettingByKey("usdt_withdrawal_min") || 0;
        let withdrawalMaxAmount = getSettingByKey("usdt_withdrawal_max") || 0;
        let usdt_withdrawal_charges = getSettingByKey("usdt_withdrawal_charges") || 0;
        setWMinAmount(withdrawalMinAmount);
        setWMaxAmount(withdrawalMaxAmount);
        setWithdrawalFee(usdt_withdrawal_charges);

        let withdrawalAmount2 = parseFloat(withdrawalAmount);
        let withdrawalMinAmount2 = parseFloat(withdrawalMinAmount);
        let withdrwalMaxAmount2 = parseFloat(withdrawalMaxAmount);
        
        if (withdrawalAmount2 < withdrawalMinAmount2) {
            setCanConfirmWithdrawal(false);
            setAmountError(parseMessage(t("min withdrawal amount: %amount USDT"), { "%amount": withdrawalMinAmount2 }));
        }
        else if (withdrawalAmount2 > withdrwalMaxAmount2) {
            setCanConfirmWithdrawal(false);
            setAmountError(parseMessage(t("max withdrawal amount: %amount USDT"), { "%amount": withdrwalMaxAmount2 }));
        }
        else {
            setAmountError("");
            if (transactionPasswordOtp && walletAddress) {
                setCanConfirmWithdrawal(true);
            } else {
                setCanConfirmWithdrawal(false);
            }
        }


        setWithdrawalFinal(withdrawalAmount - withdrawalFee);

    }, [withdrawalAmount, transactionPasswordOtp, walletAddress, withdrawalFee, t]);

    useEffect(() => {
        if (minerQty <= 0) {
            setCanConfirmMiner(false);
            setMinerError(t("Min miner to purchase: 1"));
        }
        else {
            let requestUsdt = minerQty * 1;
            setMinerError("");
            if (parseFloat(requestUsdt) > parseFloat(total_usdt)) {
                setMinerError(t("Balance not enough"));
                setCanConfirmMiner(false);
            } else {
                setCanConfirmMiner(true);
                setMinerError("");
            }
        }
    }, [minerQty, total_usdt, t]);

    const sendOtpForm = async () => {
        const apiCall = authApiCall("otp/request", {
            type: otpType,
            email: email
        }, (response) => {
            if (response.result === 1) {
                setSendOtp(true)
                setCountdown(time);
                Notify.success(t("OTP have send to your email"));
            }
        });
        await apiCall(navigate, t);
    };

    const confirmWithdrawalFormSubmit = async (e) => {
        e.preventDefault();
        if (parseFloat(withdrawalAmount) > parseFloat(total_usdt)) {
            Notify.failure(t("Not enought USDT to withdraw"));
            return false;
        }
        setWithdrawalConfirmModal(true);
        setWithdrawalModal(false);

    };

    const handleConfirmWithdrawalButton = () => {
        setTransactionPasswordFormType("withdrawal");
        setPasswordModal(true);
    }

    const confirmPassword = () => {

        if (transactionPasswordFormType === "withdrawal") {
            handleConfirmWithdrawalFormSubmit()
        } else if (transactionPasswordFormType === "miner") {
            handleConfirmMinerFormSubmit()
        }
    }

    const handleConfirmWithdrawalFormSubmit = async () => {

        const apiCall = createApiCall("member/withdrawUsdt", {
            type: "trc20",
            amount: withdrawalAmount,
            wallet_address: walletAddress, 
            transaction_password: transactionPassword,
            otp: transactionPasswordOtp,
        }, (response) => {
            if (response.result === 1) {
                ModalService.showModal(<div>{t("Withdrawal Successful!!")}</div>, { type: "happy" });
                setTransactionPasswordOtp(null);
                setWalletAddress(null);
                setWithdrawalAmount(null);
                setTransactionPassword(false);
                setWithdrawalConfirmModal(false);
                setPasswordModal(false);

                updateWalletLocalStorage(response.wallet);
                updateBalance();
                inputRef.current.value = '';
            } else {
                if (response.message_code === "401") {
                    ModalService.showModal(<div>{t("Wrong transaction password")}</div>, { type: "error" });
                } else {
                    ModalService.showModal(<div>{t(response.message_code)}</div>, { type: "error" });
                }
            }
        });
        await apiCall(navigate, t);
    };

    const handleConfirmMinerFormSubmit = async () => {

        const apiCall = createApiCall("wallet/topUpMiner", {
            amount: minerQty,
            transaction_password: transactionPassword,
        }, (response) => {
            if (response.result === 1) {
                ModalService.showModal(<div>{t("Purchase Successful!!")}</div>, { type: "happy" });
                let member_details = response.member
                setLocalStorageItems(member_details);
                setPasswordModal(false);
                setMinerModal(false);
                inputRef.current.value = '';
            } else {
                ModalService.showModal(<div>{t(response.message_code)}</div>, { type: "error" });
            }
        });
        await apiCall(navigate, t);
    };

    const handleWithdrawBtn = () => {
        setWithdrawalModal(true)
        setUsdtModal(false)
    }

    const confirmMinerForm = (e) => {
        e.preventDefault();
        setTransactionPasswordFormType("miner");
        setPasswordModal(true);
    } 
    
    const handleMinerMinusClick = () => {
        if (minerQty > 1) {
            setMinerQty(minerQty - 1);
        }
    };

    const handleMinerAddClick = () => {
        setMinerQty(minerQty + 1);
    };

    const { audio } = useContext(AudioContext);
    const [audioCheckboxState, setAudioCheckboxState] = useState(localStorage.getItem('audio'));

    let bonusEffect = new Audio();
    bonusEffect.volume = 1;

    useEffect(() => {
    
        if (audioCheckboxState === "1") {
        } else {
            // Stop background music if playing
            if (bonusEffect) {
                bonusEffect.pause();
                bonusEffect.currentTime = 0;
            }
        }

        // Cleanup on unmount
        return () => {
            if (bonusEffect) {
                bonusEffect.pause();
                bonusEffect.currentTime = 0;
            }
        };
    }, [audioCheckboxState]);

    const confirmRedeemMine = async (package_id) => {
        const apiCall = createApiCall("wallet/redeemMine", {
            package_id: package_id
        }, (response) => {
            if (response.result === 1) {
                ModalService.showModal(<div>{t("Redeem Successful!!")}</div>, { type: "excited" });

                if (audioCheckboxState === "1") {
                    let loopCount = 0;
                    const handleAudioEnded = () => {
                        loopCount++;
                        if (loopCount < 2) {
                            bonusEffect.play();
                        } else {
                            bonusEffect.removeEventListener('ended', handleAudioEnded);
                        }
                    };
                    bonusEffect.addEventListener('ended', handleAudioEnded);
                    bonusEffect.play();
                }

                setBonusModal(false)
                if (response.slot) {
                    updateSlotLocalStorage(response.slot);
                    updateSlot();
                }
                if (response.member) {
                    updateWalletLocalStorage(response.member.wallet);
                    updateBalance();
                }

                loadSetting()
                summaryReport()
                fetchRedeemInfo()
                subAccountGet()
            } else {
                ModalService.showModal(<div>{t(response.message_code)}</div>, { type: "error" });
            }
        })
        await apiCall(navigate, t);
    };

    const handleAudioCheckboxChange = (e) => { 
        let audio1 = e.target.checked ? "1" : "0";
        setAudioCheckboxState(audio1); 
        handleChangeAudioFormSubmit(audio1);
        localStorage.setItem('audio', audio1);
    };

    const handleChangeAudioFormSubmit = async (audioCheckboxState) => {
        const apiCall = createApiCall("member/updateAudit", {
            audio: audioCheckboxState
        }, (response) => {
        });
        await apiCall(navigate, t);
    };

    const [ bgMusic, setBgMusic ] = useState(localStorage.getItem('bgMusic') ?? 'bg');
    
    useEffect(() => {
        if (audio && audioCheckboxState === "1") { 
            audio.play();
        } else  {
            audio.currentTime = 0;
            audio.pause();
        }
    }, [audioCheckboxState, audio, bgMusic]);

    const [redeemProgress1, setRedeemProgress1] = useState(0);
    const [redeemProgress2, setRedeemProgress2] = useState(0);
    const [canSubmitRedeem2, setCanSubmitRedeem2] = useState(false);
    const [pointModal, setPointModal] = useState(false);
    const [puValue, setPuValue] = useState(0);

    useEffect(() => {
        if (redeemInfo.value && !isNaN(redeemInfo.value)) {

            let required_value = parseFloat(redeemInfo.value / 2).toFixed(2);
            setPuValue(required_value);

            let progress1 = parseFloat(total_usdt) / parseFloat(required_value) * 100;
            if (progress1 >= 100) {
                progress1 = 100;
            }
            progress1 = parseFloat(progress1).toFixed(2);
            setRedeemProgress1(progress1);
            let progress2 = parseFloat(total_point) / parseFloat(required_value) * 100;
            if (progress2 >= 100) {
                progress2 = 100;
            }
            progress2 = parseFloat(progress2).toFixed(2);
            setRedeemProgress2(progress2);

            if ((progress2 === 100.00 && progress1 === 100.00)) {
                setCanSubmitRedeem2(true);
            } else {
                setCanSubmitRedeem2(false);
            }
        }
    }, [redeemInfo, total_point, total_usdt]);

    const [slotDetail, setSlotDetail] = useState(JSON.parse(localStorage.getItem('slot') ?? '[]'));
    const updateSlot = () => {
        setSlotDetail(JSON.parse(localStorage.getItem('slot')));
    };

    const confirmRedeemMiner = async (package_id) => {
        const apiCall = createApiCall("wallet/redeemMiner", {
            qty: redeemMinerQty
        }, (response) => {
            if (response.result === 1) {
                ModalService.showModal(<div>{t("Redeem Successful!!")}</div>, { type: "excited" });
                setPointModal(false)

                if (response.slot) {
                    updateSlotLocalStorage(response.slot);
                    updateSlot();
                }
                if (response.member) {
                    updateWalletLocalStorage(response.member.wallet);
                    updateBalance();
                }

            } else {
                ModalService.showModal(<div>{t(response.message_code)}</div>, { type: "error" });
            }
        })
        await apiCall(navigate, t);
    };

     
    const [ redeemMinerQty, setRedeemMinerQty ] = useState(0);
    const [ requiredMinerPoint, setRequiredMinerPoint ] = useState(0);
    const [ requiredUSDT, setRequiredUSDT ] = useState(0);
    const [ totalRedeemMiner, setTotalRedeemMiner ] = useState(0);
    const [ minerRedeemError, setMinerRedeemError ] = useState(null);

    const handleMinusClick = () => {
        if (redeemMinerQty > 1) {
            setRedeemMinerQty(redeemMinerQty - 1);
        }
    };

    const handleAddClick = () => {
        setRedeemMinerQty(redeemMinerQty + 1);
    };

    useEffect(() => { 
        setRequiredMinerPoint(redeemMinerQty);
        setRequiredUSDT(redeemMinerQty); 

    },[redeemMinerQty]);

    useEffect(() => {  
        let minerRedeemError2 = "";
        if((total_usdt>= requiredUSDT && total_point > requiredMinerPoint)){  
            setCanSubmitRedeem2(true); 
        } else { 
            setCanSubmitRedeem2(false); 
        } 
        
        if(total_usdt < requiredUSDT ){
            minerRedeemError2 += t("not enought usdt")+"\n"; 
        }
        if(total_point < requiredMinerPoint ){
            minerRedeemError2 += t("not enought miner point")+"\n"; 
        }
        
        if(minerRedeemError2 !== ""){
            setMinerRedeemError(minerRedeemError2);
        }
        let totalRedeemMiner2 = (parseFloat(requiredUSDT) + parseFloat(requiredMinerPoint)) ;
        setTotalRedeemMiner(totalRedeemMiner2);

    },[requiredMinerPoint, requiredUSDT, total_usdt, total_point, t]);
 

    const available_sub_account = getValue('available_sub_account', 0);
    const [canOpenSubaccountModal, setCanOpenSubaccountModal] = useState(false);
    const onOpenSubAcconuntClick = () => {
        setCanOpenSubaccountModal(true) 
    };
    const confirmOpenSubaccount = async () => {
        const apiCall = createApiCall("registerSubAccount", { 
        }, (response) => {
            ModalService.showModal(<div>{t("Register successful!!")}</div>,{type: "happy"});

            setCanOpenSubaccountModal(false);
            // setSubaccountModal(false);

            subAccountGet()
        });
        await apiCall(navigate, t);
    };

    useEffect(() => { 
        if (available_sub_account > 0) {
            setCanOpenSubaccount(true);
        } else {
            setCanOpenSubaccount(false);
        }

    }, [available_sub_account]);


    return (
        <>
            <div className="profile">
                <div className="account-wrapper">
                    <div className="account-rank">
                        <img src={rankImages[rank]} width={70} alt={t("rank")} />
                        <h4>{rankTitle[rank]}</h4>
                    </div>

                    <div className="account-info">
                        <div className="account-info-box" style={{ backgroundImage: `url('${assets.BORDER_GEM_INFO}')` }}>
                            <div className="account-info-row">
                                <div className="account-info-item">
                                    <h5>{username}</h5>
                                </div>
                                <span onClick={() => copyToClipboard(email, "email")}>
                                    <img src={assets.BTN_COPY} width={20}  alt="copy"  />
                                </span>
                            </div>
                            <div className="account-info-row">
                                <div className="account-info-item">
                                    <label>{t("Referral ID")}</label>
                                    <h6>{referral}</h6>
                                </div>
                                <span onClick={() => copyToClipboard(referral, "referral")}>
                                    <img src={assets.BTN_COPY} width={20} alt="copy"   />
                                </span>
                            </div>
                            <div className="account-info-lists">
                                <div className="account-info-list-item">
                                    {starsArray.map((src, index) => (
                                        <img key={index} src={`${src}`} width={30} alt="star" />
                                    ))}
                                </div>
                                <div className="account-info-list-item">
                                    <Progress percentage="50" showPivot={false} />
                                </div>
                                <div className="account-info-list-item">
                                    <h6>{t("Transaction")}: {transaction}</h6>
                                    <h6>{t("Mine Profit")}: {mineProfit}</h6>
                                    <h6>{t("Mine Value")}: {mineValue}</h6>
                                    <h6>{t("Team Reward")}: {teamReward}</h6>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="account-shortcut">
                        <div className="account-shortcut-wrapper">
                            <div
                                className="account-shortcut-btn"
                                onClick={() => setUsdtModal(true)}
                            >
                                <img className="shortcut-btn-bg" src={assets.BUTTON_COIN_BASE} width={80}  alt="base"  />
                                <img className="shortcut-btn-icon" src={assets.USDT_BTN} width={40}  alt="usdt"  />
                                <span className="account-total">{total_usdt}</span>
                            </div>
                            <div
                                className="account-shortcut-btn"
                                onClick={() => setMinerModal(true)}
                            >
                                <img className="shortcut-btn-bg" src={assets.BUTTON_COIN_BASE} width={80} alt="base"   />
                                <img className="shortcut-btn-icon" src={assets.MINER_BTN} width={40}  alt="miner"  />
                                <span className="account-total">{total_miner}</span>
                            </div>
                            <div
                                className="account-shortcut-btn"
                                onClick={() => setBonusModal(true)}
                            >
                                <img className="shortcut-btn-bg" src={assets.BUTTON_COIN_BASE} width={80}  alt="base"  />
                                <img className="shortcut-btn-icon" src={assets.COIN_BONUS} width={40}  alt="bonus"  />
                                <span className="account-total">{total_bonus}</span>
                            </div>
                            <div
                                className="account-shortcut-btn"
                                onClick={() => setPointModal(true)}
                            >
                                <img className="shortcut-btn-bg" src={assets.BUTTON_COIN_BASE} width={80} alt="base"   />
                                <img className="shortcut-btn-icon" src={assets.COIN_POINT} width={40}  alt="point"  />
                                <span className="account-total">{total_point}</span>
                            </div>
                        </div>
                    </div>

                    <div className="account-nav">
                        <div
                            className='btn-nav'
                            style={{ backgroundImage: `url('${assets.PROFILE_BAR_GREY_BLUE}')` }}
                            onClick={() => setSettingModal(true)}
                        >
                            <span className="btn-nav-icon"><img src={assets.ICON_SETTING} height={25} alt="settings"   /></span>
                            <span className="btn-nav-title">{t("settings")}</span>
                        </div>
                        <div
                            className='btn-nav'
                            style={{ backgroundImage: `url('${assets.PROFILE_BAR_GREY_BLUE}')` }}
                            onClick={() => setSubaccountModal(true)}
                        >
                            <span className="btn-nav-icon"><img src={assets.ICON_SWITCH} height={25}  alt="switch"  /></span>
                            <span className="btn-nav-title">{t("Sub Account")}</span>
                        </div>
                        <div
                            className='btn-nav'
                            style={{ backgroundImage: `url('${assets.PROFILE_BAR_GREY_BLUE}')` }}
                            onClick={() => setSecurityModal(true)}
                        >
                            <span className="btn-nav-icon"><img src={assets.ICON_SECURITY} height={25}  alt="security"  /></span>
                            <span className="btn-nav-title">{t("Security")}</span>
                        </div>
                        <div
                            className='btn-nav'
                            style={{ backgroundImage: `url('${assets.PROFILE_BAR_GREY_BLUE}')` }}
                            onClick={() => setWithdrawalModal(true)}
                        >
                            <span className="btn-nav-icon"><img src={assets.ICON_WITHDRAWAL} height={25}  alt="withdrawal"  /></span>
                            <span className="btn-nav-title">{t("Withdrawal")}</span>
                        </div>
                        <div
                            className='btn-nav'
                            style={{ backgroundImage: `url('${assets.PROFILE_BAR_GREY_BLUE}')` }}
                            onClick={() => setLogoutModal(true)}
                        >
                            <span className="btn-nav-icon"><img src={assets.ICON_LOGOUT} height={25} alt="logout"   /></span>
                            <span className="btn-nav-title">{t("Logout")}</span>
                        </div>
                    </div>
                </div>

                {/*setLogoutModal */}
                <LogoutModal
                    isOpen={logoutModal}
                    handleClose={() => setLogoutModal(false)}
                    confirmLogout={confirmLogout}
                    imageSources={imageSources}
                    imageAltTexts={imageAltTexts}
                />

                {/*setSettingModal(language) */}
                <SettingsModal
                    isOpen={settingModal}
                    handleClose={() => setSettingModal(false)}
                    handleAudioCheckboxChange={handleAudioCheckboxChange}
                    audioCheckboxState={audioCheckboxState}
                    lang={lang}
                    handleUpdateLang={handleUpdateLang}
                    languages={languages}
                />

                {/*setBonusModal */}
                <RedeemModal
                    isOpen={bonusModal}
                    handleClose={() => setBonusModal(false)}
                    title="Redeem Bonus"
                    redeemInfo={redeemInfo}
                    redeemProgress={redeemProgress}
                    totalBonus={total_bonus}
                    canSubmitRedeem={canSubmitRedeem}
                    onRedeemClick={() => confirmRedeemMine(redeemInfo.package_id)}
                    silverStarterIcon={assets.SILVERSTARTER}
                    goldStarterIcon={assets.GOLDSTARTER}
                >
                </RedeemModal>

                {/*setPointModal */}
                <RedeemMinerModal
                    isOpen={pointModal}
                    handleClose={() => setPointModal(false)}
                    title="Redeem Bonus"
                    redeemInfo={redeemInfo}
                    redeemProgress1={redeemProgress1}
                    redeemProgress2={redeemProgress2}
                    totalPoint={total_point}
                    totalUsdt={total_usdt}
                    puValue={puValue}
                    canSubmitRedeem={canSubmitRedeem2}
                    totalRedeemMiner={totalRedeemMiner}
                    redeemMinerQty={redeemMinerQty}
                    handleMinusClick={handleMinusClick}
                    handleAddClick={handleAddClick}
                    requiredMinerPoint={requiredMinerPoint}
                    requiredUSDT={requiredUSDT}
                    minerRedeemError={minerRedeemError}
                    onRedeemClick={() => confirmRedeemMiner(redeemInfo.package_id)}
                    silverStarterIcon={assets.SILVERSTARTER}
                    goldStarterIcon={assets.GOLDSTARTER} 
                >
                </RedeemMinerModal>

                {/*setMinerModal */}
                <PurchaseMinerModal
                    isOpen={minerModal}
                    handleClose={() => setMinerModal(false)}
                    title="Purchase Miner"
                    onSubmit={confirmMinerForm}
            
                    minerQty={minerQty}
                    handleMinusClick={handleMinerMinusClick}
                    handleAddClick={handleMinerAddClick}
                    
                    error={minerError}
                    canSubmit={canConfirmMiner} 
                    icon={assets.MINER_BTN}
                    inputBg={assets.PASSWORD_INPUT_BG}
                > 
                </PurchaseMinerModal> 

                {/*setUsdtModal */}
                <WalletModal
                    isOpen={usdtModal}
                    handleClose={() => setUsdtModal(false)}
                    title="USDT Balance"
                    walletAddress={trc20_wallet_address}
                    disabled={true}
                    onCopyClick={() => copyToClipboard(trc20_wallet_address, "TRC 20 Wallet address")}
                    onWithdrawClick={handleWithdrawBtn}
                >
                </WalletModal>

                {/*subaccountModal */}
                <SubAccountModal
                    isOpen={subaccountModal}
                    handleClose={() => setSubaccountModal(false)}
                    subaccountLists={subaccountLists}
                    onOpenSubAcconuntClick={onOpenSubAcconuntClick}
                    canOpenSubaccount={canOpenSubaccount}
                    username={username}
                    email={email}
                    rankImages={rankImages}
                    rankTitle={rankTitle}
                    swapAccount={swapAccount}
                    openSubaccount={openSubaccount}
                    MemberStatus={MemberStatus}
                    BadgeActive={BadgeActive}
                />

                {/*canOpenSubaccount */}
                <ConfirmOpenSubAccount
                    isOpen={canOpenSubaccountModal}
                    handleClose={() => setCanOpenSubaccountModal(false)} 
                    confirmOpenSubaccount={confirmOpenSubaccount}
                    imageSources={imageSources}
                    imageAltTexts={imageAltTexts}
                />

                {/*setSecurityModal */}
                <SecurityModal
                    isOpen={securityModal}
                    handleClose={() => setSecurityModal(false)}
                    setChangePasswordModal={setChangePasswordModal}
                    setChangeTransactionPasswordModal={setChangeTransactionPasswordModal}
                />

                {/*setChangePasswordModal */}
                <ChangePasswordModal
                    isOpen={changePasswordModal}
                    handleClose={() => setChangePasswordModal(false)}
                    handleChangePasswordFormSubmit={handleChangePasswordFormSubmit}
                    inputChangeHandler={inputChangeHandler}
                    setCurrentLoginPassword={setCurrentLoginPassword}
                    setNewLoginPassword={setNewLoginPassword}
                    setConfirmLoginPassword={setConfirmLoginPassword}
                    canSubmitChangePassword={canSubmitChangePassword}
                />

                {/*setChangeTransactionPasswordModal */}
                <UpdateTransactionPasswordModal
                    isOpen={changeTransactionPasswordModal}
                    handleClose={() => setChangeTransactionPasswordModal(false)}
                    handleChangeTransactionPasswordFormSubmit={handleChangeTransactionPasswordFormSubmit}
                    inputChangeHandler={inputChangeHandler}
                    setTransactionPasswordOtp={setTransactionPasswordOtp}
                    setNewTansactionPassword={setNewTansactionPassword}
                    setConfirmTransactionPassword={setConfirmTransactionPassword}
                    canSubmitOtp={canSubmitOtp}
                    sendOtpForm={sendOtpForm}
                    canSubmitChangeTrasactionPassword={canSubmitChangeTrasactionPassword}
                    countdown={countdown}
                />


                {/*setWithdrawalModal */}
                <WithdrawalFormModal
                    isOpen={withdrawalModal}
                    handleClose={() => setWithdrawalModal(false)}
                    title="Withdrawal"
                    onSubmit={confirmWithdrawalFormSubmit} 
                    onChangeWithdrawalAmount={(e) => inputChangeHandler(setWithdrawalAmount, e)}
                    onChangeWalletAddress={(e) => inputChangeHandler(setWalletAddress, e)}
                    onChangeOTP={(e) => inputChangeHandler(setTransactionPasswordOtp, e)} 
                    inputRef={inputRef}
                    minValue={wMinAmount}
                    maxValue={wMaxAmount}
                    error={amountError}
                    canSubmit={canConfirmWithdrawal}
                    canSubmitOtp={canSubmitOtp}
                    icon={assets.USDT_BTN}
                    inputBg={assets.PASSWORD_INPUT_BG}
                    total_usdt={total_usdt}
                    sendOtpForm={sendOtpForm}
                    countdown={countdown}
                    withdrawalFee={withdrawalFee}
                >
                </WithdrawalFormModal>

                {/*setWithdrawalConfirmModal */}
                <WithdrawalConfirmModal
                    isOpen={withdrawalConfirmModal}
                    handleClose={() => {
                        setWithdrawalConfirmModal(false);
                        setWithdrawalModal(true);
                    }}
                    withdrawalAmount={withdrawalAmount}
                    withdrawalFee={withdrawalFee}
                    withdrawalFinal={withdrawalFinal}
                    handleConfirmWithdrawalButton={handleConfirmWithdrawalButton}
                />

                {/*transaction password modal */}
                <TransactionPasswordModal
                    isOpen={passwordModal}
                    handleClose={() => setPasswordModal(false)}
                    inputChangeHandler={inputChangeHandler}
                    setTransactionPassword={setTransactionPassword}
                    canSubmitPassword={canSubmitPassword}
                    confirmPassword={confirmPassword}
                />

                <Footer />
            </div>
        </>
    );
};