
import React, { useEffect, useContext, useState } from 'react';


import './App.css'; 
import "notyf/notyf.min.css";  
import '../../styles/main.scss';
import "../../assets/fontawesome/css/all.css";
 
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { useTranslation } from 'react-i18next';
import i18n from 'i18next'; // Import i18next
import { initReactI18next } from 'react-i18next';

import { AudioProvider, AudioContext } from '../AudioContext';


import enTranslations from '../i18n/en.json';
import zhTranslations from '../i18n/zh.json';  
import viTranslations from '../i18n/vi.json';  
import AppRouter from './AppRouter';

const lang = localStorage.getItem('lang');

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      zh: { translation: zhTranslations }, 
      vi: { translation: viTranslations }, 
    },
    lng: lang ? lang : 'en', 
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
});

const darkTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });
  
function App() { 
    // Initialize the language based on localStorage on app load
    useEffect(() => {
      const storedLang = localStorage.getItem('lang');  
      if (storedLang) {
        i18n.changeLanguage(storedLang); 
      }  
    }, [i18n]);

    // const { cachedAssets, updateAvailable, cacheAsset } = useCacheAssets();

    // if (updateAvailable) {
    //   // Update the assets
    //   console.log('Update available, updating assets...');
    // }
     
    return(
        <ThemeProvider theme={darkTheme} > 
          <CssBaseline />
          <AudioProvider>
            <div className={`wrapper ${lang}`} > 
              <AppRouter />
            </div> 
          </AudioProvider>
        </ThemeProvider>
    );
}

export default App;