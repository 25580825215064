
import React, { useEffect, useState, useRef, useCallback } from 'react';

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import useLocalStorage from './useLocalStorage';
import { createApiCall } from './createApiCall'
import ModalService from './ModalService';
import ModalBox from './ModalBox' 
import { parseMessage, maskEmail, setLocalStorageItems, updateSlotLocalStorage, updateWalletLocalStorage, uuBalance } from '../../Services/Utils'
 
import AssetEmptySlot from './AssetEmptySlot'
import AssetMine from './AssetMine'
import AssetWater from './AssetWater'
import GoldRock from './GoldRock'
import Countdown from './Countdown'; 
import assets from '../../assets.json';
import TransactionPasswordModal from './TransactionPasswordModal';

const DashboardMines = () => {
    const inputRef = useRef(null);

    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const navigate = useNavigate();
  
    const [email] = useLocalStorage('email', "test@test.com");
    const [total_bonus, setTotal_bonus] = useState(localStorage.getItem('bonus') || 0);
    const [total_usdt, setTotal_usdt] = useState(localStorage.getItem('usdt') || 0);
    const [total_miner, setTotal_miner] = useState(localStorage.getItem('miner') || 0);
    const [requiredMiner, setRequiredMiner] = useState(0);
    const [isGameOpen, setIsGameOpen] = useState(process.env.REACT_APP_GAME_OPEN  || false);
   
    const  updateBalance = () => { 
        const updatedValues = uuBalance();
        if (updatedValues) {
            if (updatedValues.bonus) {
                setTotal_bonus(updatedValues.bonus);
            }
            if (updatedValues.usdt) {
                setTotal_usdt(updatedValues.usdt);
            }
            if (updatedValues.miner) {
                setTotal_miner(updatedValues.miner);
            }
        }
    }

    useEffect(() => {  
        if(process.env.REACT_APP_GAME_OPEN === "true"){
            setIsGameOpen(true)
        } else { 
            setIsGameOpen(false)
        }
    }, []);

    useEffect(() => { 

        const intervalId = setInterval(() => {
        updateBalance()
        updateSlot()
        }, 1000); // 10 seconds
    
        return () => {
          clearInterval(intervalId);
        };
    }, []);
      
    const storedSettings = JSON.parse(localStorage.getItem('gameSetting'));
    const memberDetails = JSON.parse(localStorage.getItem('memberDetails')); 
    const [slotDetail, setSlotDetail] = useState(JSON.parse(localStorage.getItem('slot') ?? '[]'));


    const getSettingByKey = (key) => {
        if (storedSettings) {
            const setting = storedSettings.find(item => item.key === key);
            return setting ? setting.value : null;
        }
        return null;
    };

    const updateSlot = () => {
        setSlotDetail(JSON.parse(localStorage.getItem('slot')));
    };

    const [confirmReserveModal, setConfirmReserveModal] = useState(false);
    const [purchaseModal, setPurchaseModal] = useState(false);
    const [purchaseFormModal, setPurchaseFormModal] = useState(false);
    const [packages, setPackages] = useState([]);
    const [slot, setSlot] = useState([]);
    const [selectPackage, setSelectPackage] = useState([]);
    const [mineQty, setMineQty] = useState(1);
    const [reserveMine, setReserveMine] = useState([]);

    const imageSources = {
        error: assets.ERROR,
        success: assets.SUCCESS,
        happy: assets.HAPPY,
        excited: assets.EXCITED,
        annoying: assets.ANNONYING,
    };

    const imageAltTexts = {
        error: 'Error',
        success: 'Success',
        happy: 'Happy',
        annoying: 'Annoying'
    };

    useEffect(() => {
        packageGet();
        memberSlotDetails();
    }, []);

    const packageGet = async () => {
        const apiCall = createApiCall("package/get", {}, (response) => {
            setPackages(response.data);
        })
        await apiCall(navigate, t);
    };

    const memberSlotDetails = async () => {
        const apiCall = createApiCall("member/slot/details", {}, (response) => {
            setSlot(response.slot);
            updateSlotLocalStorage(response.slot);
            updateSlot();
            updateBalance();

            const reserveInfos = {};
            response.slot.reserve_count.forEach((reserveInfo) => {
                reserveInfos[reserveInfo.package_id] = reserveInfo;
            });
            setReserveMine(reserveInfos)

        })
        await apiCall(navigate, t);
    };


    const selectMine = useCallback((packageItem) => {
        setSelectPackage(packageItem);
        setPurchaseFormModal(true);
    }, [setSelectPackage, setPurchaseFormModal]);

    const handleMinusClick = () => {
        if (mineQty > 1) {
            setMineQty(mineQty - 1);
        }
    };

    const handleAddClick = () => {
        setMineQty(mineQty + 1);
    };

    useEffect(() => {
        let totalMiner = (selectPackage?.miner ?? 2) * mineQty;
        setRequiredMiner(totalMiner);
    }, [mineQty, selectPackage]);

    const reserveCount = reserveMine[selectPackage.package_id]?.reserve_count;
    const available = reserveMine[selectPackage.package_id]?.available;

    const [canConfirmReserve, setCanConfirmReserve] = useState(false);
    const [confirmReserveError, setConfirmReserveError] = useState(null);

    useEffect(() => {
        let reserver_error = null;

        if (available > 0 && (reserveCount+mineQty) <= available && total_miner >= requiredMiner) {
            setCanConfirmReserve(true);
        } else { 
            if(total_miner < requiredMiner){ 
                reserver_error = t("not enough miner")
            } else {
                if((reserveCount+mineQty) > available ){
                    reserver_error = t("reserve quota already finish")
                }
            }
            setCanConfirmReserve(false);
        }
        setConfirmReserveError(reserver_error);

    }, [reserveCount, available, requiredMiner]);

    const handleConfirmReserve = async () => {
        const apiCall = createApiCall("package/reserve", {
            "package_id": selectPackage.package_id,
            "quantity": mineQty,
            "transaction_password": null
        }, (response) => {
            ModalService.showModal(<div>{t("Reserve Successfull!!!")}</div>, { type: "excited" });

            updateSlotLocalStorage(response.slot);
            updateWalletLocalStorage(response.wallet);
            setPurchaseFormModal(false)
            setPurchaseModal(false)
            setConfirmReserveModal(false)

            updateSlot();
            updateBalance();
        })
        await apiCall(navigate, t);
    }

    const [mineDetailModal, setMineDetailModal] = useState(false);
    const [mineType, setMineType] = useState('');
    const [mineDetailInfo, setMineDetailInfo] = useState([]);
    const [cancelMineAmount, setCancelMineAmount] = useState(2);
    const [cancelMineReserveId, setCancelMineReserveId] = useState(0);

    const [reserveDetailInfo, setReserveDetailInfo] = useState([]);
    const [reserveDetailModal, setReserveDetailModal] = useState(false);

    const [buyMineInfo, setBuyMineInfo] = useState([]);
    const [buyMineModal, setBuyMineModal] = useState(false);

    const [wateringInfo, setWateringInfo] = useState([]);
    const [wateringModal, setWateringModal] = useState(false);

    const viewBuyMineModal = (detail, type) => {
        setMineType(type);
        setBuyMineInfo(detail);
        setBuyMineModal(true);
    };

    const viewMineDetailModal = (detail, type) => {
        setMineType(type);
        setMineDetailInfo(detail);
        setMineDetailModal(true);
    };

    const [ sellDetailModal, setSellDetailModal] = useState(false);
    
    const viewSellDetailModal = (detail, type) => {
        setMineType(type);
        setMineDetailInfo(detail);
        setSellDetailModal(true);
    };

    const viewReserveDetailModal = (detail, type) => {
        setMineType(type);
        setReserveDetailInfo(detail);
        setReserveDetailModal(true);
    };

    const viewWateringModal = (detail, type) => {
        setMineType(type);
        setWateringInfo(detail);
        setWateringModal(true);
    };

    const startMining = async (detail) => { 
        const apiCall = createApiCall("package/watering", {
            "purchase_id": detail.id
        }, (response) => {
            ModalService.showModal(<div>{t("Mining Successfull!!!")}</div>, { type: "excited" });
  
            if(response.slot){ 
                updateSlotLocalStorage(response.slot);
                updateSlot();
            }
            if(response.wallet){  
                updateWalletLocalStorage(response.wallet);
                updateBalance();
            }
        })
        await apiCall(navigate, t);
    }

    const [payMineReserveId, setPayMineReserveId] = useState(0);
    const [canSubmitPassword, setCanSubmitPassword] = useState(false);
    const [transactionPasswordFormType, setTransactionPasswordFormType] = useState(null);
    const [passwordModal, setPasswordModal] = useState(false);
    const [transactionPassword, setTransactionPassword] = useState("");

    const payMine = (mine_id) => {
        setPayMineReserveId(mine_id);
        setTransactionPasswordFormType("paymine");
        setPasswordModal(true);
        setBuyMineModal(false);
    };

    useEffect(() => {
        if (transactionPassword) {
            setCanSubmitPassword(true);
        } else {
            setCanSubmitPassword(false);
        }
    }, [transactionPassword]);

    const inputChangeHandler = (setFunction, event) => {
        setFunction(event.target.value)
    }

    const confirmPassword = () => {

        if (transactionPasswordFormType === "paymine") {
            handleConfirmPayMineSubmit()
        }
    }
    const handleConfirmPayMineSubmit = async () => {
        const apiCall = createApiCall("transaction/instantPay", {
            "transaction_id": payMineReserveId,
            "transaction_password": transactionPassword
        }, (response) => {
            ModalService.showModal(<div>{t("Purchase Successfull!!!")}</div>, { type: "excited" });

            updateSlotLocalStorage(response.slot);
            updateWalletLocalStorage(response.wallet);

            setBuyMineModal(false)
            setPasswordModal(false)
            setPayMineReserveId(null)
            setTransactionPassword(null)
            
            updateSlot();
            updateBalance();
        })
        await apiCall(navigate, t);
    }

    const cancelMine = (reserve_id) => {
        setCancelMineReserveId(reserve_id);
        setConfirmCancelModal(true);
    };

    const [confirmCancelModal, setConfirmCancelModal] = useState(false);

    const handleConfirmCancelMine = async () => {
        const apiCall = createApiCall("package/cancelReserve", {
            "reserve_id": cancelMineReserveId
        }, (response) => {
            ModalService.showModal(<div>{t("Cancel Reserve Successfull!!!")}</div>, { type: "excited" });

            updateSlotLocalStorage(response.slot);
            updateWalletLocalStorage(response.wallet);
            setReserveDetailModal(false)
            setConfirmCancelModal(false)
            setCancelMineReserveId(null)
            updateSlot();
            updateBalance();
        })
        await apiCall(navigate, t);
    }

    const appealMine = (mine_id) => {
        setAppealMineId(mine_id);
        setConfirmAppealMineModal(true);
    };

    const [appealMineId, setAppealMineId] = useState(null);
    const [confirmAppealMineModal, setConfirmAppealMineModal] = useState(false);

    const handleConfirmAppealMine = async () => {
        const apiCall = createApiCall("transaction/appeal", {
            "transaction_id": appealMineId
        }, (response) => {
            ModalService.showModal(<div>{t("Transaction has been appealed. Admin WIll Contact You As Soon As Possible!")}</div>, { type: "annoying" });

            updateSlotLocalStorage(response.slot);
            updateWalletLocalStorage(response.wallet);

            setConfirmAppealMineModal(false)
            setMineDetailModal(false)
            setAppealMineId(null)

            updateSlot();
            updateBalance();
        })
        await apiCall(navigate, t);
    }

    const [receivePackageId, setReceivePackageId] = useState(null);
    const [receiveValue, setReceiveValue] = useState(null);
    const [receiveId, setReceiveId] = useState(null);

    const viewReceiveMine = (detail) => { 
        handleConfirmReceiveMine(detail);   
    } 
    const handleConfirmReceiveMine = async (detail) => {
        const apiCall = createApiCall("transaction/acknowledgeInstantPay", {
            "transaction_id": detail.id
        }, (response) => {
            ModalService.showModal(
                <div>
                  {t("Buyer has paid! Receive Money!")}<br/>
                  {t("You Get:")}&nbsp;
                  <div className="reward_lists"> 
                    {response.received_reward && (
                        response.received_reward.map((reward, index) => (
                            <div key={index} className="reward_list_item">  
                            {Object.keys(reward).map((key, innerIndex) => (
                                (reward[key] > 0 && ( 
                                    <span key={innerIndex}> 
                                    {parseFloat(reward[key])} {key === "usdt" ? (
                                        <img src={assets.USDT_BTN} height={18} />
                                    ) : (
                                        <img src={assets.COIN_POINT} height={18} />
                                    )}
                                    </span>
                                ))
                            ))}
                            </div>
                        ))
                    )}
                    </div>
                </div>,
                { type: "happy" }
            ); 
            if(response.slot){
                updateSlotLocalStorage(response.slot);
                updateSlot();
            }
            if(response.wallet){
                updateWalletLocalStorage(response.wallet);   
                updateBalance();
            }
        })
        await apiCall(navigate, t);
    }
  
    const viewNotPaidMine = (reserveID) => { 
        handleConfirmNoPaid(reserveID); 
    }

    const handleConfirmNoPaid = async (reserveID) => {
        const apiCall = createApiCall("transaction/acknowledgeLocked", {
            "reserve_id": reserveID
        }, (response) => {
            ModalService.showModal(
                <div>
                  {t("You didn't make a decision in time!")}
                  &nbsp;{t("Will Deduct ")}
                  {parseFloat(response.lots_penalty).toFixed(0)}
                  <img src={assets.MINER_BTN} height="20" />
                </div>,
                { type: "error" }
            ); 
            if(response.slot){
               updateSlotLocalStorage(response.slot);
               updateSlot();
            }
            if(response.wallet){
                updateWalletLocalStorage(response.wallet);
                updateBalance();
            } 

        })
        await apiCall(navigate, t);
    }

    const [list1, setList1] = useState([]);
    const [list2, setList2] = useState([]);
    const [list3, setList3] = useState([]);
    const [list4, setList4] = useState([]);
    const [list5, setList5] = useState([]);
    const [list6, setList6] = useState([]);
    const [list7, setList7] = useState([]);
    const [list8, setList8] = useState([]);
    const [list9, setList9] = useState([]);

    useEffect(() => {

        const renderBuy = () => {
            if (slotDetail && slotDetail.buy) {
                return slotDetail.buy.map((detail, index) => {
                    return (
                        <div className="slotMine" key={index}>
                            <AssetMine key={index} detail={detail} type="buy" onClick={() => viewBuyMineModal(detail, "buy")} />
                        </div>
                    );
                });
            } else {
                return null;
            }
        };

        const renderSell = () => {
            if (slotDetail && slotDetail.sell) {
                return slotDetail.sell.map((detail, index) => {
                    return (
                        <div className="slotMine" key={index}>
                            <AssetMine key={index} detail={detail} type="sell" onClick={() => viewMineDetailModal(detail, "sell")} />
                        </div>
                    );
                });
            } else {
                return null;
            }
        };

        const renderSelling = () => {
            if (slotDetail && slotDetail.selling) {
                return slotDetail.selling.map((detail, index) => {
                    return (
                        <div className="slotMine" key={index}>
                            <AssetMine key={index} detail={detail} type="selling" onClick={() => viewMineDetailModal(detail, "selling")} />
                        </div>
                    );
                });
            } else {
                return null;
            }
        };

        const renderSellReceivedInstantPay = () => {
            if (slotDetail && slotDetail.sell_received_instant_pay) {
                return slotDetail.sell_received_instant_pay.map((detail, index) => {
                    return (
                        <div className="slotMine" key={index}>
                            <AssetMine key={index} detail={detail} type="sell_received_instant_pay" onClick={() => viewReceiveMine(detail, "sell_received_instant_pay")} />
                        </div>
                    );
                });
            } else {
                return null;
            }
        };

        const renderWatering = () => {
            if (slotDetail && slotDetail.watering) {
                return slotDetail.watering.map((detail, index) => {
                    return (
                        <div className="slotMine" key={index}>
                            <AssetWater
                                key={index}
                                detail={detail}
                                type="watering"
                                onClickOne={() => viewWateringModal(detail, "watering")}
                                onClickTwo={() => startMining(detail)}
                            />
                        </div>
                    );
                });
            } else {
                return null;
            }
        };

        const renderReserved = () => {
            if (slotDetail && slotDetail.reserved) {
                return slotDetail.reserved.map((detail, index) => {
                    return (
                        <div className="slotMine" key={index}>
                            <AssetMine key={index} detail={detail} type="reserved" onClick={() => viewReserveDetailModal(detail, "reserved")} />
                        </div>
                    );
                });
            } else {
                return null;
            }
        };

        const renderSplitMatched = () => {
            if (slotDetail && slotDetail.splitMatched) {
                return slotDetail.splitMatched.map((detail, index) => {
                    return (
                        <div className="slotMine" key={index}>
                            <AssetMine key={index} detail={detail} type="splitMatched" onClick={() => viewMineDetailModal(detail, "splitMatched")} />
                        </div>
                    );
                });
            } else {
                return null;
            }
        };

        const renderUnpaid = () => {
            if (slotDetail && slotDetail.unpaid) {
                return slotDetail.unpaid.map((detail, index) => {
                    return (
                        <div className="slotMine" key={index}>
                            <AssetMine key={index} detail={detail} type="unpaid" onClick={() => viewNotPaidMine(detail.id)} />
                        </div>
                    );
                });
            } else {
                return null;
            }
        };

        const renderPending = () => {
            if (slotDetail && slotDetail.pending) {
                return slotDetail.pending.map((detail, index) => {
                    return (
                        <div className="slotMine" key={index}>
                            <AssetMine key={index} detail={detail} type="pending" onClick={() => viewSellDetailModal(detail)} />
                        </div>
                    );
                });
            } else {
                return null;
            }
        };

        const list1 = renderBuy();
        const list2 = renderSell();
        const list3 = renderSelling();
        const list4 = renderSellReceivedInstantPay();
        const list5 = renderWatering();
        const list6 = renderReserved();
        const list7 = renderSplitMatched();
        const list8 = renderUnpaid();
        const list9 = renderPending();

        setList1(list1);
        setList2(list2);
        setList3(list3);
        setList4(list4);
        setList5(list5);
        setList6(list6);
        setList7(list7);
        setList8(list8);
        setList9(list9); 
    }, [slotDetail]);

    const Silver1 = assets.SILVER1;
    const Silver2 = assets.SILVER2;
    const Silver3 = assets.SILVER3;
    const Silver4 = assets.SILVER4;
    const Silver5 = assets.SILVER5;
    const Silver6 = assets.SILVER6;
    const Silver7 = assets.SILVER7;

    const silverImages = [Silver1, Silver2, Silver3, Silver4, Silver5, Silver6, Silver7]; 

    return (
        <div className="list-wrapper"
            style={{ backgroundImage: "url('assets/bg_track.png')" }}>
            <div className="mines-lists grid-container">

                {list1}
                {list2}
                {list3}
                {list4}
                {list5}
                {list6}
                {list7}
                {list8}
                {list9}

                <div className="slotMine" >
                    <AssetEmptySlot onClick={() => setPurchaseModal(true)} />
                </div>
            </div>

            {/*transaction password modal */}
            <TransactionPasswordModal
                isOpen={passwordModal}
                handleClose={() => setPasswordModal(false)}
                inputChangeHandler={inputChangeHandler}
                setTransactionPassword={setTransactionPassword}
                canSubmitPassword={canSubmitPassword}
                confirmPassword={confirmPassword}
            />  
 
            {/* setBuyMineModal */}
            <ModalBox
                isOpen={buyMineModal}
                handleClose={() => setBuyMineModal(false)}
                title={t("Buy Mines detail")}
                className="modal-large"
            >
                <div className="mine-detail">
                    {buyMineInfo && (
                        <div className="mine-item">
                            <div className="mine-item-header"> 
                                <>
                                    <div className="mineType">
                                        <img className="mineTypeBg" src={assets.SILVERSTARTER} width={100} />
                                    </div>
                                    <h4>{t(buyMineInfo.package_name)}</h4>
                                </> 
                            </div>
                            <div className="mine-item-title">
                                <h4>{t("Auto Cancellation In")}</h4>
                                <Countdown initialSeconds={buyMineInfo.count_down} />
                            </div>
                            <div className="mine-item-detail">
                                <div className="mine-item-detail-row">
                                    <span className="item-label">{t("Value(USDT)")}</span>
                                    <span className="item-value">{buyMineInfo.value}<img src={assets.USDT_BTN} height={18} /></span>
                                </div>
                                <div className="mine-item-detail-row">
                                    <span className="item-label">{t("Income")}</span>
                                    <span className="item-value">{buyMineInfo.income}%</span>
                                </div>
                                <div className="mine-item-detail-row">
                                    <span className="item-label">{t("Level")}</span>
                                    <span className="item-value">{buyMineInfo.level} {t("Levels")}</span>
                                </div>
                            </div>
                            <button className="btnSubmit mt-4" onClick={() => payMine(buyMineInfo.id)}>
                                <img src={assets.SUBMIT_BTN} width={100} />
                                <span className="span_button">
                                    {buyMineInfo.value}
                                    <img src={assets.USDT_BTN} height={20} /></span>
                            </button>
                        </div>
                    )}
                </div>
            </ModalBox>


            {/* setReserveDetailModal */}
            <ModalBox
                isOpen={reserveDetailModal}
                handleClose={() => setReserveDetailModal(false)}
                title={t("Reserve detail")}
                className="modal-large"
            >
                <div className="mine-detail">
                    {reserveDetailInfo && (
                        <div className="mine-item">
                            <div className="mine-item-header"> 
                                <>
                                    <div className="mineType">
                                        <img className="mineTypeBg" src={assets.SILVERSTARTER} width={100} />
                                    </div>
                                    <h4>{t(wateringInfo.package_name)}</h4>
                                </>
                            </div>
                            <div className="mine-item-title">
                                <h4>{t("Reservation Start In")}</h4>
                                <Countdown initialSeconds={reserveDetailInfo.count_down} />
                            </div>
                            <div className="mine-item-detail">
                                <div className="mine-item-detail-row">
                                    <span className="item-label">{t("Value(USDT)")}</span>
                                    <span className="item-value">{reserveDetailInfo.min_price}~{reserveDetailInfo.max_price} <img src={assets.USDT_BTN} height={18} /></span>
                                </div>
                                <div className="mine-item-detail-row">
                                    <span className="item-label">{t("Income")}</span>
                                    <span className="item-value">{reserveDetailInfo.income}%</span>
                                </div>
                                <div className="mine-item-detail-row">
                                    <span className="item-label">{t("Level")}</span>
                                    <span className="item-value">{reserveDetailInfo.level} {t("Levels")}</span>
                                </div>
                            </div>
                            {reserveDetailInfo.can_cancel === true && (
                                <button className="btnSubmit mt-4" onClick={() => cancelMine(reserveDetailInfo.id)}>
                                    <img src={assets.SUBMIT_BTN} width={100} />
                                    <span>{t("Cancel")}</span>
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </ModalBox>

            {/* setWateringModal */}
            <ModalBox
                isOpen={wateringModal}
                handleClose={() => setWateringModal(false)}
                title={t("Mining detail")}
                className="modal-large modal-mining"
            >
                <div className="mine-detail">
                    {wateringInfo && (
                        <div className="mine-item">
                            <div className="mine-item-header">
                                <h4>{t(wateringInfo.package_name)}</h4>
                                <div className="mineType">
                                    {wateringInfo.watering_count >= 0 && wateringInfo.watering_count < silverImages.length && (
                                        <img className="mineTypeBg" src={silverImages[wateringInfo.watering_count]} width={100} />
                                    )}
                                    <GoldRock />
                                </div>
                            </div>
                            <div className="mine-item-process">

                                {Array(wateringInfo.cycle).fill(0).map((_, index) => (
                                    <div key={index} className={`circle ${index <= wateringInfo.watering_count ? 'active' : ''}`}>
                                        <span>LV{index}</span>
                                    </div>
                                ))}
                            </div>
                            <div className="mine-item-detail">
                                <div className="mine-item-detail-row">
                                    <span className="item-label">{t("Value(USDT)")}</span>
                                    <span className="item-value">{wateringInfo.value} <img src={assets.USDT_BTN} height={18} /></span>
                                </div>
                                <div className="mine-item-detail-row">
                                    <span className="item-label">{t("Income")}</span>
                                    <span className="item-value">{wateringInfo.income}%</span>
                                </div>
                                <div className="mine-item-detail-row">
                                    <span className="item-label">{t("Level")}</span>
                                    <span className="item-value">{wateringInfo.level}</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </ModalBox>

            {/* setMineDetailModal */}
            <ModalBox
                isOpen={mineDetailModal}
                handleClose={() => setMineDetailModal(false)}
                title={t("Mines detail")}
                className="modal-large modal-mining"
            >
                <div className="mine-detail">
                    {mineDetailInfo && (
                        <div className="mine-item">
                            <div className="mine-item-header">

                                <div className="mineType">
                                    <img className="mineTypeBg" src={assets.SILVERLVL_COMPLETE} width={100} />
                                </div>
                                <h4>{t(mineDetailInfo.package_name)}</h4>
                            </div>
                            <div className="mine-item-title">
                                {mineDetailInfo.appeal === 0 && (
                                    <Countdown initialSeconds={mineDetailInfo.count_down} />
                                )}
                                {mineType === "buy" && mineDetailInfo.status === "pair" && (
                                    <>
                                        <h4>{t("Auto Cancellation in")}</h4>
                                        <Countdown initialSeconds={mineDetailInfo.count_down} />
                                    </>
                                )}
                            </div>
                            <div className="mine-item-detail">
                                <div className="mine-item-detail-row">
                                    <span className="item-label">{t("Value(USDT)")}</span>
                                    <span className="item-value">{mineDetailInfo.value} <img src={assets.USDT_BTN} height={18} /></span>
                                </div>
                                <div className="mine-item-detail-row">
                                    <span className="item-label">{t("Income")}</span>
                                    <span className="item-value">{mineDetailInfo.income}%</span>
                                </div>
                                <div className="mine-item-detail-row">
                                    <span className="item-label">{t("Level")}</span>
                                    <span className="item-value">{mineDetailInfo.level} {t("Levels")}</span>
                                </div>
                            </div>

                            {mineDetailInfo.status === "waiting" && mineDetailInfo.can_cancel === true && (
                                <div className="mt-3">
                                    <button className="btnSubmit" onClick={() => cancelMine(mineDetailInfo.id)}>
                                        <img src={assets.SUBMIT_BTN} width={100} />
                                        <span>{t("Cancel")}</span>
                                    </button>
                                </div>
                            )}
                            {mineDetailInfo.status === "pair" && mineDetailInfo.appeal === 1 && (
                                <div className="mt-3">
                                    <button className="btnSubmit" onClick={() => appealMine(mineDetailInfo.id)}>
                                        <img src={assets.SUBMIT_BTN} width={100} />
                                        <span>{t("Appeal")}</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </ModalBox>

            {/* setSellDetailModal */}
            <ModalBox
                isOpen={sellDetailModal}
                handleClose={() => setSellDetailModal(false)}
                title={t("Mines detail")}
                className=" modal-mining"
            >
                <div className="mine-detail">
                    {mineDetailInfo && (
                        <div className="mine-item">
                            <div className="mine-item-header">

                                <div className="mineType">
                                    <img className="mineTypeBg" src={assets.SILVERLVL_COMPLETE} width={100} />
                                </div>  
                                <h4>{t(mineDetailInfo.package_name)}</h4>
                            </div>
                            <div className="mine-item-title">
                           
                            </div>
                            <div className="mine-item-detail">
                                <div className="mine-item-detail-row">
                                    <span className="item-label">{t("Value(USDT)")}</span>
                                    <span className="item-value">{mineDetailInfo.value} <img src={assets.USDT_BTN} height={18} /></span>
                                </div>
                                <div className="mine-item-detail-row">
                                    <span className="item-label">{t("Income")}</span>
                                    <span className="item-value">{mineDetailInfo.income}%</span>
                                </div>
                                <div className="mine-item-detail-row">
                                    <span className="item-label">{t("Level")}</span>
                                    <span className="item-value">{mineDetailInfo.level} {t("Levels")}</span>
                                </div>
                            </div> 
                        </div>
                    )}
                </div>
            </ModalBox>

            {/*setConfirmAppealMineModal */}
            <ModalBox
                isOpen={confirmAppealMineModal}
                handleClose={() => setConfirmAppealMineModal(false)}
                title={t("Are You Sure Want To Appeal This Transaction?")}
            >
                <div className="modal-notif-content">
                    <div className="modal-icon">
                        <img
                            src={imageSources["annoying"]}
                            width={80}
                            alt={imageAltTexts["annoying"] || 'Icon'}
                        />
                    </div>
                    <h4>{t("Are You Sure Want To Appeal This Transaction?")}</h4>
                </div>
                <div className="modal-notif-footer">
                    <button className="btnSubmit" onClick={handleConfirmAppealMine}>
                        <img src={assets.SUBMIT_BTN} width={100} />
                        <span>{t("Yes")}</span>
                    </button>
                </div>
            </ModalBox>
  
            {/*setConfirmCancelModal */}
            <ModalBox
                isOpen={confirmCancelModal}
                handleClose={() => setConfirmCancelModal(false)}
                title={t("Are You Sure Want To Cancel Reserve?")}
            >
                <div className="modal-notif-content">
                    <div className="modal-icon">
                        <img
                            src={imageSources["error"]}
                            width={80}
                            alt={imageAltTexts["error"] || 'Icon'}
                        />
                    </div>
                    <h4>{t("Are You Sure Want To Cancel Reserve?")}{cancelMineAmount} <img className="shortcut-btn-icon" src={assets.MINER_BTN} height={15} />{t("Will Be Confiscated")}</h4>
                </div>
                <div className="modal-notif-footer">
                    <button className="btnSubmit" onClick={handleConfirmCancelMine}>
                        <img src={assets.SUBMIT_BTN} width={100} />
                        <span>{t("Yes")}</span>
                    </button>
                </div>
            </ModalBox>
 
            {/* setPurchaseModal */}
            <ModalBox
                isOpen={purchaseModal}
                handleClose={() => setPurchaseModal(false)}
                title={t("Purchase Mine")}
                className="modal-large"
            >
                <div className="purchase-mine">

                    {packages && packages.length > 0 ? (
                        packages.map((packageItem, index) => (
                            <div className="mine-item" key={index}>
                                <div className="mine-item-header">
                                    <img src={assets.SILVERSTARTER} width={100} />
                                    <h4>{t(packageItem.name)}</h4>
                                </div>
                                <div className="mine-item-title">
                                    <h4>{t("Reservation End In:")}</h4>
                                    <h4 className="timer">{packageItem.end_matching}</h4>
                                </div>
                                <div className="mine-item-detail">
                                    <div className="mine-item-detail-row">
                                        <span className="item-label">{t("Value(USDT)")}</span>
                                        <span className="item-value">{packageItem.min_price}~{packageItem.max_price}</span>
                                    </div>
                                    <div className="mine-item-detail-row">
                                        <span className="item-label">{t("Income")}</span>
                                        <span className="item-value">{packageItem.bonus}%</span>
                                    </div>
                                    <div className="mine-item-detail-row">
                                        <span className="item-label">{t("Level")}</span>
                                        <span className="item-value">{packageItem.cycle}</span>
                                    </div>
                                </div>
                                <div className="mine-item-footer">
                                    {!isGameOpen ? (
                                        <div className="text-alert alert-box">{t("coming soon")}</div>
                                    ) : (
                                        <>
                                            <button className="btnSubmit" onClick={() => selectMine(packageItem)} disabled={!(total_miner >= packageItem.miner)}>
                                                <img src={assets.SUBMIT_BTN} width={100} />
                                                <span>{packageItem.miner} <img className="shortcut-btn-icon" src={assets.MINER_BTN} height={15} /></span>
                                            </button>
                                            {total_miner < packageItem.miner && (
                                                <div className="text-alert">({t("not enough minse")})</div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
            </ModalBox>

            {/* setPurchaseFormModal */}
            <ModalBox
                isOpen={purchaseFormModal}
                handleClose={() => setPurchaseFormModal(false)}
                title={t("Purchase Mine")}
            >
                <div className="purchase-mine-form">

                    {selectPackage ? (
                        <div className="mine-item">
                            <div className="mine-item-header"> 
                                <img src={assets.SILVERSTARTER} width={100} />
                                <h4>{t(selectPackage.name)}</h4>
                            </div>
                            <div className="mine-item-form">
                                <span
                                    className={`btn-action btn-minus ${mineQty === 1 ? 'inactive' : ''}`}
                                    onClick={handleMinusClick}
                                >
                                    <img src={assets.BTN_PM} width="40" />
                                    <span>-</span>
                                </span>
                                <span className="mineQty" style={{ backgroundImage: `url(${assets.TEXT_INPUT_BG})` }}>
                                    {mineQty}
                                </span>
                                <span className="btn-action btn-add" onClick={handleAddClick}>
                                    <img src={assets.BTN_PM} width="40" />
                                    <span>+</span>
                                </span>
                            </div>
                            <div className="mine-item-footer mt-4">
                                <div className="mine-purchase-info">{t("Today Reserve Limit")}: {reserveCount}/{available}</div>

                                <button
                                    className="btnSubmit mt-1"
                                    disabled={!canConfirmReserve}
                                    onClick={() => setConfirmReserveModal(true)}
                                >
                                    <img src={assets.SUBMIT_BTN} width={100} />
                                    <span>{requiredMiner} <img className="shortcut-btn-icon" src={assets.MINER_BTN} height={15} /></span>
                                </button>
                                {!canConfirmReserve && (
                                    <div className="text-alert mt-2">({confirmReserveError})</div>
                                )} 
                            </div>
                        </div>
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
            </ModalBox>

            {/*setConfirmReserveModal */}
            <ModalBox
                isOpen={confirmReserveModal}
                handleClose={() => setConfirmReserveModal(false)}
                title={t("Are You Sure Want To Reserve This Mine?")}
            >
                <div className="modal-notif-content">
                    <div className="modal-icon">
                        <img
                            src={imageSources["success"]}
                            width={80}
                            alt={imageAltTexts["success"] || 'Icon'}
                        />
                    </div>
                    <h4>{t("Are You Sure Want To Reserve This Mine?")}</h4>
                </div>
                <div className="modal-notif-footer">
                    <button className="btnSubmit" onClick={handleConfirmReserve}>
                        <img src={assets.SUBMIT_BTN} width={100} />
                        <span>{t("Yes")}</span>
                    </button>
                </div>
            </ModalBox>


        </div>
    );
}

export default DashboardMines;