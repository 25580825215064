// LogoutModal.js
import React from 'react';  
import { useTranslation } from 'react-i18next';
import ModalBox from './ModalBox'  
import assets from '../../assets.json';

const LogoutModal = ({
  isOpen,
  handleClose,
  confirmLogout,
  imageSources,
  imageAltTexts
}) => {
  const { t } = useTranslation();
 
  return (

    <ModalBox
      isOpen={isOpen}
      handleClose={handleClose}
      title={t("Logout")}
    >
      <div className="modal-notif-content">
        <div className="modal-icon">
          <img
            src={imageSources["error"]}
            width={80}
            alt={imageAltTexts["error"] || 'Icon'}
          />
        </div>
        <h4>{t("Are You Sure Want To Logout?")}</h4>
      </div>
      <div className="modal-notif-footer">
        <button className="btnSubmit" onClick={confirmLogout}>
          <img src={assets.SUBMIT_BTN} width={100} />
          <span>{t("Logout")}</span>
        </button>
      </div>
    </ModalBox>
  );
};

export default LogoutModal;