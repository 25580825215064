// src/GoldRock.js
import React from 'react';
import '../../styles/animation.scss'; // Import CSS file from src/styles

const GoldRock = ({level}) => {
  return (
    <>   
      <div className="mine_blink blink1 gold" />  
      <div className="mine_grow grow2 gold" />   
      {level >=  2 && (
        <>
          <div className="mine_grow grow1 gold" />       
        </>
      )}
      {level >=  4 && (
        <> 
          <div className="mine_grow grow3 gold" />     
        </>
      )}
      {level >= 5 && (
        <div className="mine_blink blink2 gold" />   
      )}
    </>
  );
};

export default GoldRock;
