import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ModalBox from './ModalBox' 
import { parseMessage } from '../../Services/Utils'  

import {  
    Input,  
    InputGroup, 
} from "reactstrap"; 

import assets from '../../assets.json';

const WithdrawalFormModal = ({
    isOpen,
    handleClose,
    title,
    onSubmit,
    onChangeWithdrawalAmount,
    onChangeWalletAddress,
    onChangeOTP,
    inputRef,
    minValue,
    maxValue,
    error,
    canSubmit, 
    icon,
    inputBg,
    total_usdt,
    sendOtpForm,
    countdown,
    canSubmitOtp,
    withdrawalFee,
    children,
}) => {
  const { t } = useTranslation();
  const inputElement = useRef(null);
 
  return (
    <ModalBox
      isOpen={isOpen}
      handleClose={handleClose}
      title={t(title)}
      className={"modal-large"}
    >
      <div className="transfer-form withdrawal-form">
        <form className="form" onSubmit={onSubmit} method="POST" autoComplete="off">
          <div className="transfer-content">
            <div className="form-group">
              <span className="bar-coin btn-usdt">
                <img className="bar-coin-icon" src={`assets/coin_USDT.png`} height={35} alt="usdt" />
                <img className="bar-coin-bg" src={`assets/bar_coin_amount.png`} height={30} alt="coin" />
                <span className="bar-coin-input">{total_usdt}</span>
              </span>
            </div>
            <div className="form-group">
              <label>{t("Withdrawal Amount")}</label>
              <div className="input" style={{ backgroundImage: `url(${inputBg})` }}>
                <input
                  ref={inputRef}
                  max={maxValue}
                  min={minValue}
                  type="number"
                  onChange={onChangeWithdrawalAmount}
                  required
                />
                <img className="input-transfer-icon" src={icon} height={20} alt="transfer" />
              </div>
              {error && (
                <div className="errorMessage">{error}</div>
              )}
            </div>
            <div className="form-group">
              <label>{t("Wallet Address")}</label>
              <div className="input" style={{ backgroundImage: `url(${inputBg})` }}>
                <input
                  ref={inputRef}
                  className="input-password"
                  onChange={onChangeWalletAddress}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label>{t("OTP")}</label>
              <InputGroup className="input-group-alternative" style={{ backgroundImage: "url('assets/bar_fill_text_01.png')" }}>
                <Input
                  ref={inputRef}
                  name="Transaction_OTP"
                  placeholder={t("Enter OTP")} 
                  onChange={onChangeOTP}
                  required
                />
                <span className="btn btn-request">
                  <button type="button" className="btnSubmit" disabled={!canSubmitOtp} onClick={sendOtpForm}>
                    <img src={assets.SUBMIT_BTN} width={100} alt="button" />
                    <span>{t("Request")}</span>
                    <span className="btn-remark"> {countdown > 0 ? `(${countdown} seconds)` : ''}</span>
                  </button>
                </span>
              </InputGroup>
            </div>
            <div className="form-group">
              <div className="chain-info">
                <label>{t("Chain Name")}</label>
                <div className="chainName">{t("TRC20")}</div>
                <div className="withdrawal-remark">
                  <div dangerouslySetInnerHTML=
                    {{__html: parseMessage(t("Every Withdrawal will Charged +%withdrawalFee Usdt Processing Fee"), {
                      "%withdrawalFee": `${withdrawalFee}<img className="bar-coin-icon" src=${assets.USDT_BTN} height="22" alt="coin" />`,
                    })}} />

                  <div>{t("Withdrawal time will be T+1.(Every Monday till Friday,rest on Weekend)")}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="transfer-footer">
            <button className="btnSubmit" disabled={!canSubmit}>
              <img src={assets.SUBMIT_BTN} width={100} alt="button" />
              <span>{t("withdraw")}</span>
            </button>
          </div>
        </form>
        {children}
      </div>
    </ModalBox>
  );
};

export default WithdrawalFormModal;