
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'   
import { useTranslation } from 'react-i18next'; 
import { format, parse } from 'date-fns';  
   
import { parseMessage, maskEmail, setLocalStorageItems, updateWalletLocalStorage, uuBalance } from '../../Services/Utils'  
import useLocalStorage from './useLocalStorage';
import ModalService from './ModalService';  
import BtnTransfer from './BtnTransfer';  
import Footer from './Footer'; 
import ModalBox from './ModalBox'
import  { createApiCall }  from './createApiCall'

import { DatetimePicker, Field } from 'react-vant'
import { List } from 'react-vant'; 
import { Notify } from 'notiflix/build/notiflix-notify-aio';
   
export default function Alliance() {  
 
    const navigate = useNavigate();   
    const { t } = useTranslation();
    const [ owner ] = useLocalStorage('username');
    const [ username, setUsername  ] = useLocalStorage('username'); 
    const [ member_id ] = useLocalStorage('member_id');
    const [ list, setList] = useState([])
    const [ finished, setFinished] = useState(false)
    const [ offset, setOffset ] = useState(0) 
    const [ upline, setUpline ] = useState([])

    const today = new Date();
    const formattedToday = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;

    const [ fromDate, setFromDate ] = useState(formattedToday); 
    const [ toDate, setToDate ] = useState(formattedToday); 
    const [ minAmount, setMinAmount ] = useState(0); 
    const [ transferMaxAmount, setTransferMaxAmount ] = useState(0); 
    
    useEffect(() => {    
        loadSetting(); 
    }, []); 

    useEffect(() => {    
        alliance();
    }, [username, fromDate, toDate]); 
 
    const alliance =  async () => {
        const apiCall = createApiCall("downline/get", {
            offset: offset, 
            username: username,
            start_date: fromDate,
            end_date: toDate,
        }, (response) => { 
            let downline= response.downline  
            setUpline(response.upline); 

            if(downline.length > 0){ 
                let totalLength = offset + downline.length
                setOffset(totalLength) 
                setList(v => [...v, ...downline])
            }
            if (downline.length === 0) {
                setFinished(true)
            }
        })
        await apiCall(navigate, t);
    };

    const [total_bonus, setTotal_bonus] = useState(localStorage.getItem('bonus') || 0);
    const [total_usdt, setTotal_usdt] = useState(localStorage.getItem('usdt') || 0);
    const [total_miner, setTotal_miner] = useState(localStorage.getItem('miner') || 0);
         
    const  updateBalance = () => { 
        const updatedValues = uuBalance();
        if (updatedValues) {
            if (updatedValues.bonus) {
                setTotal_bonus(updatedValues.bonus);
            }
            if (updatedValues.usdt) {
                setTotal_usdt(updatedValues.usdt);
            }
            if (updatedValues.miner) {
                setTotal_miner(updatedValues.miner);
            }
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            updateBalance()
        }, 10000); // 10 seconds
    
        return () => {
          clearInterval(intervalId);
        };
      }, []);
    
    const loadSetting =  async () => {
        const apiCall = createApiCall("login", {}, (response) => { 
            let member_details= response.data.member_details 
            setLocalStorageItems(member_details);
            updateBalance();
        })
        await apiCall(navigate, t);
    };

    const goto = (path) => { 
        navigate('/' + path);
    }; 
 
    const handleFromDateConfirm = (date) => {
        const formattedFromDate = format(date, 'dd-MM-yyyy');
        setFromDate(formattedFromDate);

        const fromDateObj = parse(formattedFromDate, 'dd-MM-yyyy', new Date());
        const toDateObj = parse(toDate, 'dd-MM-yyyy', new Date());

        // Check if fromDate is greater than toDate
        if (toDate && fromDateObj > toDateObj) {
            setToDate(formattedFromDate);
        }
        setOffset(0) 
        setList(() => []);   
    };
      
    const handleToDateConfirm = (date) => {
        const formattedToDate = format(date, 'dd-MM-yyyy');
        setToDate(formattedToDate);

        const fromDateObj = parse(fromDate, 'dd-MM-yyyy', new Date());
        const toDateObj = parse(formattedToDate, 'dd-MM-yyyy', new Date());

        // Check if toDate is less than fromDate
        if (fromDate && toDateObj < fromDateObj) {
            setFromDate(formattedToDate);
        }
        setOffset(0) 
        setList(() => []);   
    };

    const uplineTeam = (name) => { 
        setUsername(name)
        setOffset(0) 
        setList(() => []);   
    }

    const downlineTeam = (name) => {
        setUsername(name)
        setOffset(0) 
        setList(() => []);   
    }
      
    const copyToClipboard  = (email) => { 
        // Create a new text area element
        const textArea = document.createElement('textarea');
        textArea.value = email;

        // Append the text area to the DOM
        document.body.appendChild(textArea);

        // Select and copy the text from the text area
        textArea.select();
        document.execCommand('copy');

        // Clean up
        document.body.removeChild(textArea);

        Notify.success(t("Email have copy to clipboard"));   
    }; 
    
    const rankImages = {
        0: "assets/rank_Unrank.png",
        1: "assets/rank_Silver.png",
        2: "assets/rank_Platinum.png",
        3: "assets/rank_Gold.png",
        4: "assets/rank_Diamond.png",
        5: "assets/rank_Bronze.png",
    };
    
    const rankTitle = {
        0: t("VIP 0"),
        1: t("VIP 1"),
        2: t("VIP 2"),
        3: t("VIP 3"),
        4: t("VIP 4"),
        5: t("VIP 5"),
    }; 
    const [isOpenTransfer, setIsOpenTransfer] = useState(false);
    const [transferType, setTransferType] = useState("usdt");
    const [transferTo, setTransferTo] = useState("");
    const [transferAmount, setTransferAmount] = useState(0);  
    const [transactionPassword, setTransactionPassword] = useState("");
    const [receiverMemberId, setReceiverMemberId] = useState(0);
    const [apiEndPoint, setApiEndPoint] = useState("transferUsdt");
 
    const storedSettings = JSON.parse(localStorage.getItem('gameSetting'));  
    const memberDetails = JSON.parse(localStorage.getItem('memberDetails'));

    const getSettingByKey = (key) => {
        return storedSettings && storedSettings[key] ? storedSettings[key] : null; 
    };

    const getMemberByKey = (key) => {
        return memberDetails && memberDetails[key] ? memberDetails[key] : null;
    };

    useEffect(() => {
        let transferMinAmount = 0;
        if(transferType === "miner"){
            transferMinAmount = getSettingByKey("min_lots_transfer") || 0;
            setTransferMaxAmount(total_miner);
            setApiEndPoint("transferMiner")
        }else{
            transferMinAmount = getSettingByKey("usdt_min_transfer") || 0;
            setTransferMaxAmount(total_usdt);
            setApiEndPoint("transferUsdt")
        }
 
        setMinAmount(transferMinAmount)
        setTransferAmount(transferMinAmount)
        setAmountError("")
    }, [transferType]); 

    const handleOpenTransfer = (username, memberId) => { 
        updateBalance()
        setIsOpenTransfer(true); 
        setTransferTo(username); 
        setReceiverMemberId(memberId); 
    };
  
    const handleCloseTransfer = () => {
        setIsOpenTransfer(false);
    }; 
  
    const handleChangeAmount = (event) => {
        setTransferAmount(event.target.value); 
    };

    const handleTransactionPassword = (event) => {
        setTransactionPassword(event.target.value); 
    };
 
    const [isOpenTransactionPassword, setIsOpenTransactionPassword] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [canConfirm, setCanConfirm] = useState(false);
    const [amountError, setAmountError] = useState("");
 
    useEffect(() => { 
        let transferAmount2 = parseFloat(transferAmount);
        let minAmount2 = parseFloat(minAmount);
        let transferMaxAmount2 = parseFloat(transferMaxAmount);
        if(transferAmount2 < minAmount2){
            setCanConfirm(false);
            setAmountError(parseMessage(t("min transfer amount: %amount %type"), {"%amount": minAmount, "%type":transferType}));
        }
        else if(transferAmount2 > transferMaxAmount2){
            setCanConfirm(false);
            setAmountError(parseMessage(t("max transfer amount: %amount %type"), {"%amount": transferMaxAmount, "%type":transferType}));
        } 
        else{
            setCanConfirm(true);
            setAmountError("");
        } 
        
    }, [transferAmount]); 
 
    useEffect(() => { 
        if(transactionPassword){
            setCanSubmit(true);
        }else{
            setCanSubmit(false);
        } 
    }, [transactionPassword]); 

    const submitTransfer = () => { 
        setIsOpenTransfer(false);
        setIsOpenTransactionPassword(true); 
    }
    const handleCloseTransactionPassword = () => {
        setIsOpenTransactionPassword(false);
    }; 

    const confirmTransfer = () => { 
        transferForm(); 
    }
 
    const transferForm = async () => {
        const apiCall = createApiCall("wallet/"+apiEndPoint, {
            amount: transferAmount, 
            transaction_password: transactionPassword,
            receiver_member_id: receiverMemberId
        }, (response) => {
            if(response.result === 1){   
                let member_details= response.member 
                setLocalStorageItems(member_details);
                updateBalance();
         
                ModalService.showModal(<div>{t("transfer_"+response.message_code)}</div>,{type: "success"}); 
                resetForm(); 
            }
        });
        await apiCall(navigate, t);
      };

    const resetForm = () => {
        setTransferAmount(0) 
        setTransactionPassword("")
        setReceiverMemberId(0)
        setIsOpenTransactionPassword(false)
        setIsOpenTransfer(false)
    }

    const cardBg1 = "assets/bar_up_line.png";
    const cardBg2 = "assets/bar_Down_line.png";
    const arrowUp = "assets/icon_arrow_up.png";
    const arrowDown = "assets/icon_arrow_down_dark.png";
    const copyBtn = "assets/copy.png";
    const usdtBtn = "assets/coin_USDT.png";
    const minerBtn = "assets/coin_worker.png";
    const button_trading_select_L = "assets/button_trading_select_L.png";
    const button_trading_select_L_un = "assets/button_trading_select_L_un.png";
    const submitBtn = "assets/button_ok.png";
    const passwordIcon = "assets/icon_password.png";
    const passwordInputBg = "assets/bar_fill_text_01.png";
    const textInputBg = "assets/bar_password_keepin.png";
   
    return (
    <>
        <div className="alliance">   
            <div className="list-wrapper"
            style={{ backgroundImage: "url('assets/border_activity.png')" }}> 
                <div className="list-title">
                    <h2>{t("Alliances")}</h2>
                </div>
                <div className="list-filter"> 
                    <h4>{t("Show Rank")}</h4>
                    <div className="list-filter-inline"> 
                        <DatetimePicker
                            popup={{
                                round: true,
                            }}  
                            type='date'
                            defaultValue={fromDate} 
                            onConfirm={handleFromDateConfirm}
                            maxDate={today}
                            confirmButtonText={t("confirm")}
                            cancelButtonText={t("close")}
                            >
                            {(val, _, actions) => {
                                return (
                                <input value={fromDate} type="text" className="input" style={{ backgroundImage: `url(${textInputBg})` }} onClick={() => actions.open()} readOnly/> 
                                )
                            }}
                        </DatetimePicker>
                        <span>{t("To")}</span>
                        <DatetimePicker
                            popup={{
                                round: true,
                            }}
                            type='date'
                            defaultValue={toDate} 
                            onConfirm={handleToDateConfirm}
                            maxDate={today}
                            confirmButtonText={t("confirm")}
                            cancelButtonText={t("close")}
                            >
                            {(val, _, actions) => {
                                return (
                                <input value={toDate} type="text" className="input" style={{ backgroundImage: `url(${textInputBg})`}} onClick={() => actions.open()} readOnly/> 
                                )
                            }}
                        </DatetimePicker> 

                    
                    </div>
                </div>
                
                <div className="list-content">  
                    <List finished={finished} onLoad={alliance}>
                    <div className="list-members">
                    {upline.length !== 0 && (
                            <div className="member-card upline-card" style={{ backgroundImage: `url('${cardBg1}')` }}> 
                                <div className="member-card-left">  
                                    <div className="member-card-team">  
                                        {parseInt(member_id) !== parseInt(upline.member_id) && (
                                            <span onClick={() => uplineTeam(upline.upline_username)}>
                                                <img src={arrowUp} width={15}  alt={t("show team info")}/> 
                                            </span>
                                        )}
                                    </div>
                                    <div className="member-card-rank">  
                                        <img src={rankImages[upline.rank]} width={50}  alt={t("rank")}/> 
                                        <h4>{rankTitle[upline.rank]}</h4>
                                    </div>
                                </div>
                                <div className="member-card-right">
                                    {parseInt(member_id) === parseInt(upline.member_id) ? (
                                        <h4>{t("My Team")}</h4> 
                                    ) : (
                                        <h4>
                                            <span className="btn-copy" onClick={() => copyToClipboard(upline.name)}><img src={copyBtn} width={15} alt={t("copy")}/></span>
                                            {maskEmail(upline.name)}
                                        </h4> 
                                    )} 
                                    
                                    <div className="member-card-detail">
                                        <div className="member-card-info">
                                            <div style={{color:'#3323ff'}}>{t("Total Direct Active")}: {upline.total_direct_active}</div> 
                                            <div style={{color:'#00e92c'}}>{t("Total Group Active")}: {upline.total_group_active}</div> 
                                            <div style={{color:'#00e6e9'}}>{t("Total Group Reserve")}: {upline.today_group_reserve}</div> 
                                            <div style={{color:'#9d05d6'}}>{t("Total Group Transaction")}: {upline.total_group_transactions}</div> 
                                            <div style={{color:'#d6058f'}}>{t("Personal Transaction")}: {upline.personal_transaction}</div> 
                                            <div style={{color:'#ff0000'}}>{t("Total Unpaid Reserve")}: {upline.personal_total_unpaid_reserve} 
                                            {parseInt(member_id) === parseInt(upline.member_id) && ` ${t("Self")}:${upline.self_reserve_unpaid}`}

                                    </div> 
                                        </div>

                                        <div className="member-card-action">   
                                            {parseInt(member_id) !== parseInt(upline.member_id) && (
                                                <BtnTransfer onClick={() => handleOpenTransfer(upline.name, upline.member_id)} />   
                                            )}
                                        </div>
                                    </div>
                                </div> 
                            </div>
                    )}
                    {list.map((_, i) => ( 
                        <div className="member-card"  key={i} title={i + 1} style={{ backgroundImage: `url('${cardBg2}')` }}> 
                            <div className="member-card-left">  
    
                                <div className="member-card-team">  
                                    <span onClick={() => downlineTeam(_.name)}>
                                        <img src={arrowDown} width={15} alt={t("show team info")}/> 
                                    </span>
                                </div> 
                                <div className="member-card-rank">  
                                    <img src={rankImages[_.rank]} width={50}  alt={t("rank")}/> 
                                    <h4>{rankTitle[_.rank]}</h4>
                                </div>

                                
                            </div>
                            
                            <div className="member-card-right"> 
                                <h4>
                                    <span className="btn-copy" onClick={() => copyToClipboard(_.name)}><img src={copyBtn} width={15} alt={t("copy")}/></span>
                                    {maskEmail(_.name)}
                                </h4>  
                                <div className="member-card-detail">
                                    <div className="member-card-info">
                                        <div style={{color:'#3323ff'}}>{t("Total Direct Active")}: {_.total_direct_active}</div> 
                                        <div style={{color:'#009b1d'}}>{t("Total Group Active")}: {_.total_group_active}</div> 
                                        <div style={{color:'#009c9e'}}>{t("Total Group Reserve")}: {_.today_group_reserve}</div> 
                                        <div style={{color:'#9d05d6'}}>{t("Total Group Transaction")}: {_.total_group_transactions}</div> 
                                        <div style={{color:'#d6058f'}}>{t("Personal Transaction")}: {_.personal_transaction}</div> 
                                        <div style={{color:'#ff0000'}}>{t("Total Unpaid Reserve")}: {_.personal_total_unpaid_reserve}</div> 
                                    </div>

                                    <div className="member-card-action">   
                                        {member_id !== _.member_id && (
                                            <BtnTransfer onClick={() => handleOpenTransfer(_.name, _.member_id)} />   
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>  
                    ))}
                    </div>
                    </List>
                </div>
            </div>

            <ModalBox
                isOpen={isOpenTransfer}
                onClose={handleCloseTransfer}
                handleClose={handleCloseTransfer}
                title={t("Transfer")}
            >
                <div className="transfer-form">
                    <div className="transfer-header">
                        <span 
                        className={'btn-transfer-type'}  
                        onClick={() => setTransferType("usdt")}>
                            <img className="btn-transfer-icon" src={usdtBtn} height={35}/>
                            {transferType === 'usdt' ? (
                                <img className="btn-transfer-bg" src={button_trading_select_L} height={50}/>
                            ): (
                                <img className="btn-transfer-bg" src={button_trading_select_L_un} height={50}/>
                            )}
                        </span>
                        <span 
                        className={'btn-transfer-type rigth'}  
                        onClick={() => setTransferType("miner")}>
                            <img className="btn-transfer-icon" src={minerBtn} height={35}/>
                            {transferType === 'miner' ? (
                                <img className="btn-transfer-bg" src={button_trading_select_L} height={50}/>
                            ): (
                                <img className="btn-transfer-bg" src={button_trading_select_L_un} height={50}/>
                            )}
                        </span>
                    </div>
                    <div className="transfer-content">
                        <div className="form-group">
                            {transferType === 'usdt' ? ( 
                                <span className="bar-coin btn-usdt"> 
                                    <img className="bar-coin-icon" src={`assets/coin_USDT.png`} height={35}/>
                                    <img className="bar-coin-bg" src={`assets/bar_coin_amount.png`} height={30} />
                                    <span className="bar-coin-input">{total_usdt}</span>
                                </span>
                            ):(
                                <span className="bar-coin btn-miner"> 
                                    <img className="bar-coin-icon" src={`assets/coin_worker.png`} height={35}/>
                                    <img className="bar-coin-bg" src={`assets/bar_coin_amount.png`} height={30} />
                                    <span className="bar-coin-input">{total_miner}</span>
                                </span>
                            )} 
                        </div>
                        <div className="form-group">
                            <label>{t("Member")}</label>
                            <input value={transferTo} type="text" className="input" style={{backgroundImage: `url(${textInputBg})` }} readOnly/> 
                        </div>
                        <div className="form-group">
                            <label>{t("Transfer Amount")}</label>
                            <div className="input" style={{ backgroundImage: `url(${textInputBg})` }}>  
                                <input min={1} max={transferMaxAmount} value={transferAmount} type="number" onChange={handleChangeAmount}/> 
                                {transferType === 'miner' ? (
                                    <img className="input-transfer-icon" src={minerBtn} height={20}/>
                                ): (
                                    <img className="input-transfer-icon" src={usdtBtn} height={20}/>
                                )}
                            </div>
                            {amountError && (
                                <div className="errorMessage">{amountError}</div>
                            )}
                        </div>
                    </div>
                    <div className="transfer-footer">
                        <button className="btnSubmit" onClick={submitTransfer} disabled={!canConfirm}>
                            <img src={submitBtn} width={100} />
                            <span>{t("Transfer")}</span>
                        </button>
                    </div>
                </div>
            </ModalBox> 

            <ModalBox
                isOpen={isOpenTransactionPassword}
                onClose={handleCloseTransactionPassword}
                handleClose={handleCloseTransactionPassword}
                title={t("Enter transaction password")}
                className={"transaction_password_form"}
            >
                <div className="transfer-form">
                    <div className="transfer-header"> 
                        <img className="btn-transfer-bg" src={passwordIcon} height={60}/>
                    </div>
                    <div className="transfer-content">
                        
                        <div className="form-group">
                            <label>{t("Transaction Password")}</label>
                            <div className="input" style={{ backgroundImage: `url(${passwordInputBg})` }}> 
                                <input className="input-password" type="password" value={transactionPassword}  onChange={handleTransactionPassword}/> 
                            </div>
                        </div>
                    </div>
                    <div className="transfer-footer"> 
                        <button 
                            className="btnSubmit" 
                            onClick={confirmTransfer} 
                            disabled={!canSubmit} 
                        > 
                            <img src={submitBtn} width={100} alt="submit" />
                            <span>{t("OK")}</span>
                        </button>
                    </div>
                </div>
            </ModalBox> 

            <Footer/>
        </div> 
    </>
    );
};