import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ModalBox from './ModalBox'  
import assets from '../../assets.json';

const TransactionPasswordModal = ({
  isOpen,
  handleClose,
  inputChangeHandler,
  setTransactionPassword,
  canSubmitPassword,
  confirmPassword,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);

  return (
    <ModalBox
      isOpen={isOpen}
      handleClose={handleClose}
      title={t("Enter transaction password")}
      className={"transaction_password_form"}
    >
      <div className="transfer-form">
        <div className="transfer-header">
          <img className="btn-transfer-bg" src={assets.ICON_PASSWORD} height={60}  alt="transfer" />
        </div>
        <div className="transfer-content">
          <div className="form-group">
            <label>{t("Transaction Password")}</label>
            <div className="input" style={{ backgroundImage: `url(${assets.PASSWORD_INPUT_BG})` }}>
              <input
                ref={inputRef}
                className="input-password"
                type="password"
                onChange={(e) => inputChangeHandler(setTransactionPassword, e)}
              />
            </div>
          </div>
        </div>
        <div className="transfer-footer">
          <button
            className="btnSubmit"
            onClick={confirmPassword}
            disabled={!canSubmitPassword}
          >
            <img src={assets.SUBMIT_BTN} width={100} alt="submit" />
            <span>{t("OK")}</span>
          </button>
        </div>
      </div>
    </ModalBox>
  );
};

export default TransactionPasswordModal;