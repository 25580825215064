import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'; 

const BtnMining = ({ initialSeconds, className, onClick }) => {

  const { t }     = useTranslation();
  const [seconds, setSeconds] = useState(initialSeconds);
  const intervalIdRef = useRef(null);

  useEffect(() => {
    setSeconds(initialSeconds);
  }, [initialSeconds]);
  
  
  useEffect(() => {
    if (seconds > 0) {
      const intervalId = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
      intervalIdRef.current = intervalId;
    }
    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, [seconds]);

  const formatCountdown = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsRemaining = seconds % 60;
  
    let countdownString = '';
    if (hours > 0) {
      countdownString += `${hours}H `;
    }
    if (minutes > 0) {
      countdownString += `${minutes}m `;
    }
    countdownString += `${secondsRemaining}s`;
  
    return countdownString.trim();
  };

  return (
      seconds > 0 ? (
        <span className={className} >
          {formatCountdown(seconds)}
        </span>
      ) : (
        <span className="btn-mining" onClick={onClick}>{t("mining now")}</span>
      ) 
  );
};

export default BtnMining;