import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ModalBox from './ModalBox'  
import assets from '../../assets.json';

import {  
    Input,  
    InputGroup, 
} from "reactstrap"; 

const UpdateTransactionPasswordModal = ({
  isOpen,
  handleClose,
  handleChangeTransactionPasswordFormSubmit,
  inputChangeHandler,
  setTransactionPasswordOtp,
  setNewTansactionPassword,
  setConfirmTransactionPassword,
  canSubmitOtp,
  sendOtpForm,
  canSubmitChangeTrasactionPassword,
  countdown,
}) => {
  const inputRef = useRef(null);
  const { t } = useTranslation();
 
  return (
    <ModalBox
      isOpen={isOpen}
      handleClose={handleClose}
      title={t("Update transaction password")}
      className={"update_password_form"}
    >
      <div className="transfer-form">
        <form className="form" onSubmit={handleChangeTransactionPasswordFormSubmit} method="POST" autoComplete='off'>
          <div className="transfer-content">
            <div className="form-group">
              <label>{t("OTP")}</label>
              <InputGroup className="input-group-alternative" style={{ backgroundImage: `url('${assets.PASSWORD_INPUT_BG}')` }}>
                <Input
                  ref={inputRef}
                  name="Transaction_OTP"
                  placeholder={t("Enter OTP")}
                  onChange={(e) => inputChangeHandler(setTransactionPasswordOtp, e)}
                  required
                />
                <span className="btn btn-request">
                  <button type="button" className="btnSubmit" disabled={!canSubmitOtp} onClick={sendOtpForm}>
                    <img src={assets.SUBMIT_BTN} width={100}  alt="button" />
                    <span>{t("Request")}</span>
                    <span className="btn-remark"> {countdown > 0 ? `(${countdown} seconds)` : ''}</span>
                  </button>
                </span>
              </InputGroup>
            </div>
            <div className="form-group">
              <label>{t("New Transaction Password")}</label>
              <div className="input" style={{ backgroundImage: `url(${assets.PASSWORD_INPUT_BG})` }}>
                <input
                  ref={inputRef}
                  className="input-password"
                  type="password"
                  onChange={(e) => inputChangeHandler(setNewTansactionPassword, e)}
                />
              </div>
            </div>
            <div className="form-group">
              <label>{t("Confirm Transaction Password")}</label>
              <div className="input" style={{ backgroundImage: `url(${assets.PASSWORD_INPUT_BG})` }}>
                <input
                  ref={inputRef}
                  className="input-password"
                  type="password"
                  onChange={(e) => inputChangeHandler(setConfirmTransactionPassword, e)}
                />
              </div>
            </div>
          </div>
          <div className="transfer-footer">
            <button
              className="btnSubmit"
              disabled={!canSubmitChangeTrasactionPassword}
              type="submit"
            >
              <img src={assets.SUBMIT_BTN} width={100} alt="submit" />
              <span>{t("Confirm")}</span>
            </button>
          </div>
        </form>
      </div>
    </ModalBox>
  );
};

export default UpdateTransactionPasswordModal;