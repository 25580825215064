import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ModalBox from './ModalBox'  
import assets from '../../assets.json';

const ChangePasswordModal = ({
  isOpen,
  handleClose,
  handleChangePasswordFormSubmit,
  inputChangeHandler,
  setCurrentLoginPassword,
  setNewLoginPassword,
  setConfirmLoginPassword,
  canSubmitChangePassword,
}) => {
  const inputRef = useRef(null); 
  const { t } = useTranslation();

  return (
    <ModalBox
      isOpen={isOpen}
      handleClose={handleClose}
      title={t("Change password")}
      className={"update_password_form"}
    >
      <div className="transfer-form">
        <form className="form" onSubmit={handleChangePasswordFormSubmit} method="POST">
          <div className="transfer-content">
            <div className="form-group">
              <label>{t("Current Login Password")}</label>
              <div className="input" style={{ backgroundImage: `url(${assets.PASSWORD_INPUT_BG})` }}>
                <input
                  ref={inputRef}
                  className="input-password"
                  type="password"
                  onChange={(e) => inputChangeHandler(setCurrentLoginPassword, e)}
                />
              </div>
            </div>
            <div className="form-group">
              <label>{t("New Login Password")}</label>
              <div className="input" style={{ backgroundImage: `url(${assets.PASSWORD_INPUT_BG})` }}>
                <input
                  ref={inputRef}
                  className="input-password"
                  type="password"
                  onChange={(e) => inputChangeHandler(setNewLoginPassword, e)}
                />
              </div>
            </div>
            <div className="form-group">
              <label>{t("Confirm Login Password")}</label>
              <div className="input" style={{ backgroundImage: `url(${assets.PASSWORD_INPUT_BG})` }}>
                <input
                  ref={inputRef}
                  className="input-password"
                  type="password"
                  onChange={(e) => inputChangeHandler(setConfirmLoginPassword, e)}
                />
              </div>
            </div>
          </div>
          <div className="transfer-footer">
            <button
              className="btnSubmit"
              disabled={!canSubmitChangePassword}
              type="submit"
            >
              <img src={assets.SUBMIT_BTN} width={100} alt="submit" />
              <span>{t("Confirm")}</span>
            </button>
          </div>
        </form>
      </div>
    </ModalBox>
  );
};

export default ChangePasswordModal;