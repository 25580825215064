import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalBox from './ModalBox' 
import { Progress } from 'react-vant'; 
import assets from '../../assets.json';

const RedeemModal = ({
  isOpen,
  handleClose,
  title,
  redeemInfo,
  redeemProgress,
  totalBonus,
  canSubmitRedeem,
  onRedeemClick,
  silverStarterIcon,
  goldStarterIcon, 
  children,
}) => {
  const { t } = useTranslation();

  return (
    <ModalBox
      isOpen={isOpen}
      handleClose={handleClose}
      title={t(title)}
    >
      <div className="redeem-content">
        {redeemInfo ? (
          <>
            <div className="redeem-content-body">
                <>
                  <img src={silverStarterIcon} width={100}  alt="package" />
                  <h4>{t(redeemInfo.package_title)}</h4>
                </>
              <div className="redeem-process">
                <div className="redeem-process-title">{redeemProgress}</div>
                <Progress percentage={redeemProgress}  color="#779cff"  pivotText={<img src={assets.COIN_BONUS} height={20}  alt="bonus" /> } />
                <div>{totalBonus}/{redeemInfo.value}</div>
              </div>
            </div>
            <div className="redeem-content-footer">
              <div className="redeem-title">{redeemInfo.redeem_count}/{redeemInfo.maximum_redeem}</div>
              <button
                className="btnSubmit"
                onClick={onRedeemClick}
                disabled={!canSubmitRedeem}
              >
                <img src={assets.SUBMIT_BTN} width={100} alt="submit" />
                <span>{t("Redeem")}</span>
              </button>
            </div>
          </>
        ) : (
          <p>Loading...</p>
        )}
        {children}
      </div>
    </ModalBox>
  );
};

export default RedeemModal;